import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import PayForm from './../../APP/COMPONENTS/ContentParts/PayForm';

function mapStateToProps(state) {
    return {
        PAY_MODAL: state.PAY_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Pay extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
        };

        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.PAY_MODAL !== prevProps.PAY_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.PAY_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    renderTop()
    {
        if (WIDTH > 760) {
            return (
                <div className="modal_top">
                    <a className="close-modal" onClick={() => this.props.doPay(false)}>
                        <img src={'/system_images/close.png'} />
                    </a>
                    <h4>ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð·Ð°ÐºÐ°Ð·Ð°</h4>
                </div>
            );
        } else {
            return (
                <div className="modal_top modal_mobile_top">
                    <a className="close-modal-mobile" onClick={() => this.props.doPay(false)}>
                        <img src={'/system_images/angle_left.png'} />
                    </a>
                    <h4>ÐžÐ¿Ð»Ð°Ñ‚Ð° Ð·Ð°ÐºÐ°Ð·Ð°</h4>
                </div>
            );
        }
    }

	render()
    {
        if (this.props.PAY_MODAL) {

            return (
                <div>
                    <div className="modal-overlay" onClick={() => this.props.doPay(false)}>
                    </div>
                    <div className="modal-box pay-modal">
                        {this.renderTop()}
                        <PayForm />
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Pay);