import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class UserBlock extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        }
    }

    enterLK = () =>
    {

    }

    renderNameOrMessage = () =>
    {
    	if (this.props.USER) {
    		return (
                <div>
                    <span>
                        {this.props.USER.NAME}
                    </span>
                    <i className="fa fa-angle-right"></i>
                </div>
            );
    	} else {
    		return 'Ð’Ð¾Ð¹Ñ‚Ð¸';
    	}
    }

    renderFirstLine()
    {
    	return (
	    	<Link 
	    		className="auth" 
	    		to="/personal"
	    	>
	    		{this.renderNameOrMessage()}
		    </Link>
	    );
    }

    num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderSubLine = () =>
    {
        if (this.props.USER) {
			return (
				<Link 
					className="sub_auth_done"
					to="/personal"
				>
					{this.props.USER.NAME}
				</Link>
			);
        } else {
            return (
                <Link 
                    className="sub_auth"
                    to="/personal"
                >
                    <img src="/system_images/login.png" />
                </Link>
            );
        }
    }

	render() {
    	return (
    		<div className="header_user">
                <Link 
					className="sub_auth_done"
					to="/personal"
				>
    			    <img src="/system_images/user-profile.png" />
                </Link>
    		</div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(UserBlock)
