import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CartProducts from './../../../APP/COMPONENTS/ContentParts/CartProducts';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        REC: true,
        FRIENDS_DISCOUNT: state.FRIENDS_DISCOUNT,
        PRICE_TYPE: state.PRICE_TYPE
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CartContent extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            SUM: 0,
            CART: props.CART,
            PRODUCTS: props.PRODUCTS
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let UPDATE = 0;
        if ((typeof this.props.CART !== "undefined") && (typeof this.props.PRODUCTS !== "undefined")) {
            let NEW_KEYS = Object.keys(prevProps.CART);
            let CURRENT_KEYS = Object.keys(this.props.CART);


            if (JSON.stringify(NEW_KEYS) !== JSON.stringify(CURRENT_KEYS)) {
                UPDATE = 1;
            }
        } else {
            UPDATE = 1;
        }

        if (UPDATE == 1) {
            this.setState({ SUM: 0, CART: this.props.CART, PRODUCTS: this.props.PRODUCTS });
        }
    }

    emptyCart() {
        this.props.emptyCartModal(true);
    }

    countCart() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let PRICE = PRODUCTS[ID].price;

                        if (this.props.FRIENDS_DISCOUNT) {
                            PRICE = PRICE - (Math.round(PRICE / 100 * 25));
                        }

                        if (this.props.USER && this.props.USER.price_type > 0 || this.props.PRICE_TYPE) {
                            PRICE = PRODUCTS[ID]['price' + (this.props.PRICE_TYPE ? this.props.PRICE_TYPE : this.props.USER.price_type)];
                        }

                        let ROW_SUM = PRICE * CART[ID];
                        SUM += ROW_SUM;
                    }
                }
            }
        }

        return SUM;
    }

    getSumMessage(SUM) {
        if (parseInt(SUM) < CONFIG.MINIMAL) {
            return 'ÐœÐ¸Ð½Ð¸Ð¼Ð°Ð»ÑŒÐ½Ñ‹Ð¹ Ð·Ð°ÐºÐ°Ð· Ð½Ð° ' + CONFIG.MINIMAL + ' Ñ€';
        }
    }

    renderMessage() {
        let SUM = this.countCart();
        let MESSAGE = this.getSumMessage(SUM);
        return (
            <div className="cart-message">
                <div className="flex cart_first">
                    <h4>ÐšÐ¾Ñ€Ð·Ð¸Ð½Ð°</h4>
                    <a className="empty_cart" onClick={ () => this.emptyCart() }>
                        ÐžÑ‡Ð¸ÑÑ‚Ð¸Ñ‚ÑŒ ÐºÐ¾Ñ€Ð·Ð¸Ð½Ñƒ
                    </a>
                    <div className="spacer"></div>
                    <a className="close_the_cart" onClick={ () => this.props.openCart(false) }>
                        <img src="/system_images/close_cart.png" />
                    </a>
                </div>
                <div className="flex">
                    <div className="text-cart-part">
                        { MESSAGE }
                    </div>
                    <div className="top_cart_sum sum-cart-part">
                        Ð˜Ñ‚Ð¾Ð³: <span className="sum">{ SUM } Ñ€</span>
                    </div>
                </div>
            </div>
        );
    }

    renderProducts() {
        return <CartProducts />
    }

    goToOrder() {
        if (this.props.USER) {
            window.location.href = CONFIG.API_C_DOMAIN + '/cart';
        } else {
            this.props.openLK(true);
        }
    }

    rmFixed(numb) {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    checkMaximum() {
        let PRODUCT = this.state.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    checkCartContents() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let RESULT = 0;
        let KEYS = Object.keys(CART);
        let SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID];
                        SUM += ROW_SUM;
                        RESULT = 1;
                    }
                }
            }
        }

        this.SUM = SUM;

        return RESULT;
    }

    checkCart = () => {
        let CART_OK = 0;

        let count = 0;
        for (let k in this.props.CART) {
            ++count;
        }

        if (count > 0) {
            CART_OK = this.checkCartContents();
        }

        return CART_OK;
    }

    renderCart() {
        let CART_OK = this.checkCart();

        if (CART_OK == 1) {
            return this.renderCartContent();
        } else {
            return this.renderCartEmpty();
        }
    }

    renderEmptyMessage() {
        return (
            <div className="cart-message">
                <div className="flex cart_first empty_cart_head">
                    <h4>ÐšÐ¾Ñ€Ð·Ð¸Ð½Ð°</h4>
                    <a className="close_the_cart" onClick={ () => this.props.openCart(false) }>
                        <img src="/system_images/close_cart.png" />
                    </a>
                </div>
            </div>
        );
    }

    renderCartEmpty() {
        return (
            <div className="true_cart_content">
                { this.renderEmptyMessage() }
                <div className="form-fields text-center">
                    <div className="empty_cart_block">
                        <img src="/system_images/empty_basket.png" />
                        <div className="no_p_now">Ð’ ÐºÐ¾Ñ€Ð·Ð¸Ð½Ðµ Ð¿Ð¾ÐºÐ° Ð½ÐµÑ‚ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²</div>
                        <a className="orange-button slim" onClick={ () => this.props.openCart(false) }>ÐŸÐµÑ€ÐµÐ¹Ñ‚Ð¸ Ðº ÐºÐ°Ñ‚Ð°Ð»Ð¾Ð³Ñƒ</a>
                    </div>
                </div>
            </div>
        );
    }

    renderCartContent() {
        return (
            <div className="true_cart_content">
                { this.renderMessage() }
                { this.renderProducts() }
            </div>
        );
    }

    getClass = () => {
        let CART_OK = this.checkCart();

        if (CART_OK == 1) {
            return 'modal_content';
        } else {
            return 'modal_content empty_cart_content';
        }
    }

    render() {
        return (
            <div className={ this.getClass() }>
                { this.renderCart() }
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CartContent);