import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
    	SHOW_CAT: state.SHOW_CAT,
        CATS: state.CATS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Cat extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            PRODUCT: false
        };

        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.SHOW_CAT !== prevProps.SHOW_CAT) {
            if (window.innerWidth < 1200) {
                if (!this.props.SHOW_CAT) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    renderNavIcon = (CAT) =>
    {
        let IMAGE = <div className="nav-image"></div>;

        if (CAT.ico !== "") {
            let IMAGE_SRC = CONFIG.API_C_DOMAIN + '/imgs/groups/icos/' + CAT.ico;
            IMAGE = (
                <div className="nav-image">
                    <img src={IMAGE_SRC} />
                </div>
            );
        }

        return IMAGE;
    }

    renderSubCats = (CAT) =>
    {
        let SUB_ITEMS = [];
        for (let i = 0; i < CAT.children.length; i++) {
            let SUB_CAT = CAT.children[i];
            SUB_ITEMS.push(this.renderSubLi(SUB_CAT,i));
        }

        return (
            <ul className="sub_list" key="1">
                {SUB_ITEMS}
            </ul>
        );
    }

    renderSubLi = (CAT,i) => 
    {
        return (
            <li
                key={i}
            >
                <Link to={'/' + CAT.url}
                    href={CAT.url}
                    title={CAT.title}
                    onClick={() => this.props.showCat(false)}
                >
                    {CAT.title}
                </Link>
            </li>
        );
    }

    renderCat = (CAT,i) => 
    {
        let IMAGE = this.renderNavIcon(CAT);

        if (CAT.children.length) {
            let SUB_CATS = this.renderSubCats(CAT);

            return (
                <div 
                	className="desktop_cat"
                    key={i}
                >
                    <Link to={'/' + CAT.url}
                    	onClick={() => this.props.showCat(false)}
                        title={CAT.title}
                        className="head_link"
                    >
                        {IMAGE}
                        {CAT.title}
                    </Link>
                    {SUB_CATS}
                </div>
            );
        } else {
            return (
                <div 
                	className="desktop_cat"
                    key={i}
                >
                    <Link to={'/' + CAT.url}
                        title={CAT.title}
                        onClick={() => this.props.showCat(false)}
                        className="head_link"
                    >
                        {IMAGE}
                        {CAT.title}
                    </Link>
                </div>
            );
        }
    }

    renderCats()
    {
    	let CATS = this.props.CATS;
    	let ITEMS = [];
        if (CATS) {
            for (let i = 0; i < CATS.length; i++) {
                let CAT = CATS[i];
                ITEMS.push(this.renderCat(CAT,i));
            }
        }

        return (
        	<div className="cats_holder">
				{ITEMS}
			</div>
        );
    }
    
	render()
    {
        if (this.props.SHOW_CAT) {
            return (
                <div>
                    <div className="modal-overlay" onClick={() => this.props.showCat(false)}>
                    </div>
                    <div className="modal-box catalogue_modal">
                        <a className="close-modal" onClick={() => this.props.showCat(false)}>
                            <img src="/system_images/close.png" />
                        </a>
                        {this.renderCats()}
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Cat);