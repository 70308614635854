import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductRow from './../../../APP/COMPONENTS/ContentParts/ProductRow';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CartProducts extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            UPDATED: 'NOW'
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        let UPDATE = 0;

        if ((typeof this.props.CART !== "undefined") && (typeof this.props.PRODUCTS !== "undefined")) {
            let NEW_KEYS = Object.keys(prevProps.CART);
            let CURRENT_KEYS = Object.keys(this.props.CART);


            if (JSON.stringify(NEW_KEYS) !== JSON.stringify(CURRENT_KEYS)) {
                UPDATE = 1;
            }
        } else {
            UPDATE = 1;
        }

        if (UPDATE == 1) {
            this.setState({UPDATED: new Date().getTime()});
        }
    }              

    shouldComponentUpdate(nextProps)
    {
        let UPDATE = 0;
        if ((typeof this.props.CART !== "undefined") && (typeof this.props.PRODUCTS !== "undefined")) {
            let NEW_KEYS = Object.keys(nextProps.CART);
            let CURRENT_KEYS = Object.keys(this.props.CART);


            if (JSON.stringify(NEW_KEYS) !== JSON.stringify(CURRENT_KEYS)) {
                UPDATE = 1;
            }
        } else {
            UPDATE = 1;
        }

        if (UPDATE == 1) {
            return true;
        }

        return false;
    }

    emptyCart()
    {
        this.props.emptyCartModal(true);
    }

	render()
    {
    	let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);

        let RETURN_PRODUCTS = [];

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let PRODUCT_ROW = <ProductRow key={ID} PRODUCT={PRODUCTS[ID]} />

                        RETURN_PRODUCTS.push(PRODUCT_ROW);
                    }
                }
            }
        }

        return (
            <div className="flex flex-wrap products-in-cart">
                {RETURN_PRODUCTS}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CartProducts);