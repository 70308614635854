import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'
import { CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        CATS: state.CATS,
        TOP_NAVS: state.TOP_NAVS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class TopNavs extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            ACTIVE: false,
            TOP_NAVS: props.TOP_NAVS
        }

        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.TOP_NAVS !== prevProps.TOP_NAVS) {
            if (!this.props.TOP_NAVS) {
                document.body.classList.remove("modal-open");
                document.documentElement.scrollTop = this.OFFSET;
            } else {
                this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                document.body.classList.add("modal-open");
            }
        }
    }

    checkActiveArrow = (id) =>
    {
        if (this.state.ACTIVE == id) {
            return 'nav-load-more selected';
        } else {
            return 'nav-load-more';
        }
    }

    renderSubCatsArrow = (CAT) =>
    {
        let CLASSES = this.checkActiveArrow(CAT.id);

        return (
            <div className={CLASSES}>
                <i className="fa fa-angle-right"></i>
            </div>
        );
    }

    renderNavIcon = (CAT) =>
    {
        let IMAGE = <div className="nav-image"></div>;

        if (CAT.ico !== "") {
            let IMAGE_SRC = CONFIG.API_C_DOMAIN + '/imgs/groups/icos/' + CAT.ico;
            IMAGE = (
                <div className="nav-image">
                    <img src={IMAGE_SRC} />
                </div>
            );
        }

        return IMAGE;
    }

    checkActive = (id) =>
    {
        if (this.state.ACTIVE == id) {
            return 'foldable selected';
        } else {
            return 'foldable';
        }
    }

    checkSubActive = (id) =>
    {
        if (this.state.ACTIVE == id) {
            return true;
        } else {
            return false;
        }
    }

    goTo(LINK)
    {
    	this.props.toggleNav();
    	this.props.history.push(LINK);
    }

    renderSubCats = (CAT) =>
    {
        let SUB_ITEMS = false;

        if (this.checkSubActive(CAT.id)) {
            SUB_ITEMS = [];
            for (let i = 0; i < CAT.children.length; i++) {
                let SUB_CAT = CAT.children[i];
                SUB_ITEMS.push(this.renderSubLi(SUB_CAT,i));
            }
            SUB_ITEMS = (
                <ul className="in-sub-menu" key="1">
                    {SUB_ITEMS}
                </ul>
            );
        }

        return (
            <CSSTransitionGroup 
                transitionName="slide"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
            >
                {SUB_ITEMS}
            </CSSTransitionGroup>
        );
    }

    renderSubLi = (CAT,i) => 
    {
        return (
            <li
                key={i}
            >
                <a onClick={() => this.goTo('/' + CAT.url)}
                    title={CAT.title}
                >
                    {CAT.title}
                </a>
            </li>
        );
    }

    toggleSubs = (id) =>
    {
        let SET = id;
        if (this.state.ACTIVE == id) {
            SET = false;
        }

        this.setState({ACTIVE: SET});
    }

    renderLi = (CAT,i) => 
    {
        let IMAGE = this.renderNavIcon(CAT);

        if (CAT.children.length) {
            let CLASSES = this.checkActive(CAT.id);
            let SUB_CATS_ARROW = this.renderSubCatsArrow(CAT);
            let SUB_CATS = this.renderSubCats(CAT);

            return (
                <li
                    key={i}
                >
                    <a 
                        className={CLASSES} 
                        title={CAT.title}
                        onClick={() => this.toggleSubs(CAT.id)}
                    >
                        {IMAGE}
                        {CAT.title}
                        {SUB_CATS_ARROW}
                    </a>
                    {SUB_CATS}
                </li>
            );
        } else {
            return (
                <li
                    key={i}
                >
                    <a onClick={() => this.goTo('/' + CAT.url)}
                        title={CAT.title}
                    >
                        {IMAGE}
                        {CAT.title}
                    </a>
                </li>
            );
        }
    }

	render() {
		if (!this.props.TOP_NAVS) {
			return false;
		}
    	let CATS = this.props.CATS;

        if (CATS) {
            let ITEMS = [];

            for (let i = 0; i < CATS.length; i++) {
                let CAT = CATS[i];
                ITEMS.push(this.renderLi(CAT,i));
            }

            return (
            	<div className="top_navs">
	                <ul className="main-nav">
	                    {ITEMS}
	                </ul>
                </div>
            );
        } else {
            return false;
        }  
	}

}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(TopNavs))
