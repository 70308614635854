import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import OrderForm from './../../APP/COMPONENTS/ContentParts/OrderForm';

function mapStateToProps(state) {
    return {
        ORDER_MODAL: state.ORDER_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Cart extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            SHOW: true,
            TITLE: 'ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð·Ð°ÐºÐ°Ð·'
        };

        this.OFFSET = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.ORDER_MODAL !== prevProps.ORDER_MODAL) {
            if (window.innerWidth < 1200) {
                if (!this.props.ORDER_MODAL) {
                    document.body.classList.remove("modal-open");
                    document.documentElement.scrollTop = this.OFFSET;
                    this.setState({TITLE: 'ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð·Ð°ÐºÐ°Ð·', SHOW: true})
                } else {
                    this.OFFSET = window.pageYOffset || document.documentElement.scrollTop;
                    document.body.classList.add("modal-open");
                }
            }
        }
    }

    returnToCart()
    {
        this.props.openOrder(false);
        this.props.openCart(true);
    }

    changeHead = (title, show) =>
    {
        this.setState({TITLE: title, SHOW: show})
    }

    renderBack()
    {
        if (this.state.SHOW) {
            return (
                <a className="return_to_cart" onClick={() => this.returnToCart()}>
                    <img src="/system_images/angle_left.png" /> ÐšÐ¾Ñ€Ð·Ð¸Ð½Ð°
                </a>
            )
        }
    }

    render()
    {
        if (this.props.ORDER_MODAL) {
            return (
                <div>
                    <div className="modal-overlay" onClick={() => this.props.openOrder(false)}>
                    </div>
                    <div className="modal-box static-modal cart-modal" id="cart">
                        <OrderForm changeHead={this.changeHead} />
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Cart);