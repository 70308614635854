import React, {Component} from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import InputMask from "react-input-mask";
import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import { YMaps, Map, Polygon, Rectangle, SearchControl, Placemark } from 'react-yandex-maps';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        DO_SEARCH: state.DO_SEARCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Partnership extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            TOKEN: props.TOKEN,
            NAME: '',
            PHONE: '',
            EMAIL: '',
            COMMENT: ''
        }
    }

    componentDidMount()
    {
        this.props.closeAllModals();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.match.params.url !== prevProps.match.params.url) {
            this.props.closeAllModals();
         
            this.setState({PAGE: false, URL: this.props.match.params.url, ERROR: false});
        }
        if (this.props.TOKEN !== prevProps.TOKEN) {
            this.setState({TOKEN: this.props.TOKEN});
        }
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
    }

    checkRerender()
    {
    }


    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });

        DATA.push({
            'to' : '/company/partnership',
            'title' : 'Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    validatePhone = () =>
    {
        let phoneNumber = this.state.PHONE.toString().replace(/\D+/g, '');

        if (phoneNumber.length == 11) {
            return true
        } else {
            return false
        }
    }

    saveData = async () =>
    {
            let SEND_DATA = {
                action:         'recordPartnership',
                phone:          this.state.PHONE,
                name:           this.state.NAME,
                comment:        this.state.COMMENT,
                email:          this.state.EMAIL
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);

            if (data !== false) {
                this.setState({SAVED: true});
            }
    }

    renderSaveButton()
    {
        if (this.validatePhone()) {

            if (this.state.SAVED) {
                return <button className="orange-button"><i className="fas fa-check"></i> Ð—Ð°ÑÐ²ÐºÐ° Ð¾Ñ‚Ð¿Ñ€Ð°Ð²Ð»ÐµÐ½Ð°</button>;
            } else {
                return <button className="orange-button" onClick={() => this.saveData()}>ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ Ð·Ð°ÑÐ²ÐºÑƒ</button>;
            }

            
        } else {
            return <button className="orange-button inactive-btn">ÐžÑ‚Ð¿Ñ€Ð°Ð²Ð¸Ñ‚ÑŒ Ð·Ð°ÑÐ²ÐºÑƒ</button>
        }
    }

	render() {
        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾</title>
                        <meta name="description" content="Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾"/>
                        <meta name="keywords" content="Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾" />
                    </Helmet>

                    {this.renderBreadCrumbs()}
                    <div id="products" className="page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="main-heading ">Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾</h1>
                                    <div className="login_form_block form-fields">
                                    <div className="col-12 flexed-col">
                                        <span className="in-card-label">Ð—Ð°Ð¿Ð¾Ð»Ð½Ð¸Ñ‚Ðµ Ñ„Ð¾Ñ€Ð¼Ñƒ Ð¸ Ð¼Ñ‹ ÑÐ²ÑÐ¶ÐµÐ¼ÑÑ Ñ Ð²Ð°Ð¼Ð¸!</span>
                                        </div>
                                        <br/><br/>
                                        <div className="col-12 flexed-col">
                                            <label>Ð’Ð°ÑˆÐµ Ð¸Ð¼Ñ</label>
                                            <input
                                                type="text"
                                                label="Ð’Ð°ÑˆÐµ Ð¸Ð¼Ñ"
                                                placeholder=""
                                                required="true"
                                                value={this.state.NAME}
                                                onChange={(text) => this.setState({NAME: text.target.value})}
                                                className="order-input"
                                            />
                                        </div>
                                        <div className="col-12 flexed-col">
                                            <label>Ð’Ð°Ñˆ email</label>
                                            <input
                                                label="Ð’Ð°Ñˆ email"
                                                placeholder=""
                                                required="true"
                                                type="email"
                                                value={this.state.EMAIL}
                                                onChange={(text) => this.setState({EMAIL: text.target.value})}
                                                className="order-input"
                                            />
                                        </div>
                                        <div className="col-12 flexed-col">
                                            <label>ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°</label>
                                            <InputMask
                                                mask="+7-999-999-99-99"
                                                type="tel"
                                                label="ÐÐ¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°"
                                                value={this.state.PHONE}
                                                required="true"
                                                onChange={(text) => this.setState({PHONE: text.target.value})}
                                                className="order-input"
                                            />
                                        </div>
                                        <div className="col-12 flexed-col">
                                            <label>ÐšÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹</label>
                                            <textarea
                                                type="text"
                                                label=""
                                                placeholder=""
                                                required="true"
                                                onChange={(text) => this.setState({COMMENT: text.target.value})}
                                                className="order-input"
                                            >{this.state.COMMENT}</textarea>
                                        </div>
                                        <div className="col-12 flexed-col">
                                        <div className="personal_column full mt">
                                            <div className="text-right">
                                                {this.renderSaveButton()}
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ModalsContainer />
                </div>
   
            </CSSTransitionGroup>
        );
    }
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Partnership))
