import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Copyright from './../../../APP/COMPONENTS/HeaderParts/NavBar/Copyright';
import UserBlock from './../../../APP/COMPONENTS/HeaderParts/NavBar/UserBlock';
import CatNavs from './../../../APP/COMPONENTS/HeaderParts/NavBar/CatNavs';
import SocialNetworks from './../../../APP/COMPONENTS/HeaderParts/NavBar/SocialNetworks';

function mapDispatchToProps(dispatch) {
	return bindActionCreators(ReduxActions, dispatch)
}


class NavBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			shown: false
		}
	}

	render() {
		return (
			<div className="catsDropper">
				<a className="cats_toggler" onClick={ () => { } /* this.setState({ shown: !this.state.shown }) */ }>
					<img src="/system_images/category.png" className="cats_btn" />
					<span>ÐšÐ°Ñ‚Ð°Ð»Ð¾Ð³</span>
				</a>
				<div className={ this.state.shown ? 'droppedNavs shown' : 'droppedNavs ' }>
					<CatNavs />
				</div>
			</div>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(NavBar)
