import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import CONFIG from './../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


function mapStateToProps(state, myProps) {
	return {
		PRODUCTS: state.PRODUCTS
	}
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators(ReduxActions, dispatch)
}


class Preloader extends Component {

	constructor(props) {
		super(props);
		this.state = {
			PRODUCTS: props.PRODUCTS
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.PRODUCTS !== prevProps.PRODUCTS) {
			let COMPONENT = this;
			setTimeout(function () {
				COMPONENT.setState({ PRODUCTS: true })
			}
				, 300
			);
		}
	}

	render() {
		if (this.state.PRODUCTS) {
			return false;
		}

		return (
			<div id="site_preloader">
				<div className="center">
					<div className="loader_holder">
						<img src="/system_images/logo_circle.png" />
					</div>
				</div>
			</div>
		);
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Preloader)