import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class PhoneBlock extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <a href={ 'tel: ' + CONFIG.PHONE } className="headphone">
                { CONFIG.PHONE }
            </a>
        );
    }

}

export default connect(
    false,
    mapDispatchToProps
)(PhoneBlock)
