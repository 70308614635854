import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CARD_MODAL: state.CARD_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CardForm extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);

        let SECURITY = '';
        if (props.SECURITY) {
            SECURITY = props.SECURITY;
        }

        this.state = {
            KEY: 'pk_e799ee8da07f1b383dbb4ffd12e3d',
            NUMBER: '',
            MONTH:  '',
            YEAR:   '',
            CVV:    '',
            NAME:   '',
            IFRAME: false,
            SAVING: false,
            SECURITY: SECURITY
        };
    }

    componentDidMount()
    {
        this.getAddCardUrl();
        window.addEventListener("message", this.handleFrameTasks);
    }

    getAddCardUrl = async () =>
    {
        let TOKEN = 'UNREGISTERED';
        if (this.props.USER) {
            TOKEN = this.props.USER.TOKEN;
        }

        try {
            let SEND_DATA = {
                action:         'getAddCardUrl',
                apikey:         TOKEN,
                security:       this.state.SECURITY
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.setState({IFRAME: data.URL});
            }
        } catch (error) {
            console.log(error);
            console.log('BAD PRODUCTS TIMER');
        }
    }

    componentWillUnmount()
    {
        window.removeEventListener("message", this.handleFrameTasks);
    }

    handleFrameTasks = (e) => {
        try {
            let DATA = JSON.parse(e.data);
            if (typeof DATA.message !== "PAY SUCCEE") {
                this.props.cardAdded(DATA.id);
            } else {
                this.props.cardError((new Date().getTime()) + 1);
            }
        } catch(error) {}
    }

    recordNumber = (text) => 
    {
        this.setState({NUMBER: text.target.value});
    }
    recordName = (text) => 
    {
        this.setState({NAME: text.target.value.toUpperCase()});
    }
    recordYear = (text) => 
    {
        this.setState({YEAR: text.target.value});
    }
    recordMonth = (text) => 
    {
        this.setState({MONTH: text.target.value});
    }
    recordCVV = (text) => 
    {
        this.setState({CVV: text.target.value});
    }

    addCard = async () =>
    {
        if (!this.state.SAVING) {
            this.setState({SAVING: true});
            const checkout = new window.cp.Checkout('pk_e799ee8da07f1b383dbb4ffd12e3d',document.getElementById("empty_block"));
            const result = checkout.createCryptogramPacket();

            if (typeof result.success !== "undefined") {
                try {
                    let SEND_DATA = {
                        action:         'addingCard',
                        cryptogram:     result.packet,
                        name:           this.state.NAME,
                        email:          this.props.USER.EMAIL,
                        apikey:         this.props.USER.TOKEN,
                        not_mobile:     true
                    };

                    let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
                    if (data !== false) {
                        if (typeof data.URL !== "undefined") {
                            this.setState({
                                RETURN_URL: data.RETURN_URL,
                                IFRAME: data.URL,
                                SAVING: false
                            });
                        } else {
                            this.cardAdded();
                        }
                    }
                } catch (error) {
                    console.log('BAD_CARDS_STORY');
                }
            }
            this.setState({SAVING: false});
        }
    }

    cardAdded()
    {
        this.props.addCardModal(false);
    }

    renderForm()
    {
        return (
            <div className="card_form_holder">
                <div id="empty_block">
                    <input type="hidden" data-cp="name" value={this.state.NAME} />
                    <input type="hidden" data-cp="cvv" value={this.state.CVV} />
                    <input type="hidden" data-cp="expDateYear" value={this.state.YEAR} />
                    <input type="hidden" data-cp="expDateMonth" value={this.state.MONTH} />
                    <input type="hidden" data-cp="cardNumber" value={this.state.NUMBER} />
                </div>
                <div id="cloudPaymentsCrypt">
                    <div className="cloud_light">
                        <div className="card_icons">
                            <img src="/system_images/maestro.png" />
                            <img src="/system_images/mastercard.png" />
                            <img src="/system_images/visa.png" />
                            <img src="/system_images/mir.png" />
                        </div>

                        <div className="full_card margin_bottom_15">
                            <InputMask mask="9999 9999 9999 9999" className="mask-card-number" type="text" placeholder="ÐÐ¾Ð¼ÐµÑ€ ÐºÐ°Ñ€Ñ‚Ñ‹" value={this.state.NUMBER}  onChange={(text) => this.recordNumber(text)} />
                        </div>

                        <div className="flexed_card margin_bottom_15">
                            <span>VALID<br/>THRU</span>
                            <InputMask mask="99" className="mask-card-exp" type="text" placeholder="00" value={this.state.MONTH}  onChange={(text) => this.recordMonth(text)} />
                            <div className="input-separator">/</div>
                            <InputMask mask="99" className="mask-card-exp" type="text" placeholder="00" value={this.state.YEAR}  onChange={(text) => this.recordYear(text)} />
                        </div>

                        <div className="full_card">
                            <input className="mask-card-cardholder" type="text" data-cp="name" placeholder="Ð˜Ð¼Ñ Ð²Ð»Ð°Ð´ÐµÐ»ÑŒÑ†Ð°" value={this.state.NAME}  onChange={(text) => this.recordName(text)} />
                        </div>
                    </div>
                    <div className="flexed_card padded_card">
                        <span>Ð¢Ñ€Ð¸ Ñ†Ð¸Ñ„Ñ€Ñ‹ Ð½Ð°<br/>Ð¾Ð±Ð¾Ñ€Ð¾Ñ‚Ðµ ÐºÐ°Ñ€Ñ‚Ñ‹</span>
                        <InputMask mask="999" className="mask-card-cvv" type="text" data-cp="CVC" placeholder="000" value={this.state.CVV}  onChange={(text) => this.recordCVV(text)} />
                    </div>
                </div>

                <div className="card_submit">
                    <a className="orange-button" onClick={() => this.addCard()}>
                        Ð”Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ
                    </a>
                </div>
            </div>
        );
    }

    renderIframe()
    {
        return (
            <iframe name="iframe" src={this.state.IFRAME} style={{display:'block', height:'100%', width:'100%', 'marginBottom':'20px', border:'none'}}>
                Ð’Ð°Ñˆ Ð±Ñ€Ð°ÑƒÐ·ÐµÑ€ Ð½Ðµ Ð¿Ð¾Ð´Ð´ÐµÑ€Ð¶Ð¸Ð²Ð°ÐµÑ‚ Ð¿Ð»Ð°Ð²Ð°ÑŽÑ‰Ð¸Ðµ Ñ„Ñ€ÐµÐ¹Ð¼Ñ‹!
            </iframe> 
        );
    }

	render()
    {
        if (this.state.IFRAME) {
            return this.renderIframe();
        } else {
            return '';
        }
        
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CardForm);