import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import { CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class E404 extends Component {

	constructor(props)
    {
        super(props);
    }

	render() {
		return (
			<CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
        		<div className="right-part">
        			<Helmet status="404">
        				<status>404</status>
                        <title>ÐžÐ¨Ð˜Ð‘ÐšÐ</title>
                        <meta name="description" content="ÐžÐ¨Ð˜Ð‘ÐšÐ" />
                        <meta name="keywords" content="ÐžÐ¨Ð˜Ð‘ÐšÐ" />
                    </Helmet>
					<div className="content-box text-center">
						<h4 className="special-heading">404</h4>
					    	<h5>
					    		Ðš ÑÐ¾Ð¶Ð°Ð»ÐµÐ½Ð¸ÑŽ, Ð´Ð°Ð½Ð½Ð¾Ð¹ ÑÑ‚Ñ€Ð°Ð½Ð¸Ñ†Ñ‹ Ð½ÐµÑ‚ Ð½Ð° Ð½Ð°ÑˆÐµÐ¼ ÑÐ°Ð¹Ñ‚Ðµ!
					    	</h5>
						<br />
						<div className="text-center">
							<Link to={"/"} className="btn btn-main">ÐÐ° Ð³Ð»Ð°Ð²Ð½ÑƒÑŽ</Link>
						</div>
					</div>
				</div>
				<FooterBlock />
            </CSSTransitionGroup>
		)
	}

}

export default connect(
	false,
	mapDispatchToProps
)(E404)
