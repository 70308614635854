import  React, {Component} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import reducer from './../REDUX/reducer';
import DataFetch from './../SYSTEM/DataFetch';
import App from './../SYSTEM/App.js';

const store = createStore(reducer);

export default class Router extends Component {
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter>
					<DataFetch />
					<App />
				</BrowserRouter>
			</Provider>
		);
	}
}
