import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import LazyLoad from 'react-lazyload';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';

function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        TOKEN: state.TOKEN,
        PREPARED_CATS: state.PREPARED_CATS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class BrickCatPreview extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            PREPARED_CATS: []
        };

        this.LOADING = false;
        this.LOADED = {};
    }

    renderRows = () => {
        let WIDTH = window.innerWidth;

        if (this.props.CATS) {
            let RENDERED_CATS = [];
            let firstRow = []
            let secondRow = []

            for (let i = 0; i < this.props.CATS.length; i++) {
                let CAT = this.props.CATS[i];

                const category = (
                    <div className="preview-col-3" key={ i }>
                        <Link to={ "/" + CAT.url } className="cat_preview small" style={ { backgroundImage: 'url(' + CONFIG.API_C_DOMAIN + '/imgs/groups/' + CAT.image + ')' } }>
                            <span>{ CAT.title }</span>
                        </Link>
                    </div>
                )

                RENDERED_CATS.push(
                    category
                )

                if (i % 2) {
                    secondRow.push(category)
                } else {
                    firstRow.push(category)
                }
            }

            if (WIDTH > 1000) {
                return RENDERED_CATS;
            } else {
                return (
                    <div>
                        <div className="cat_row">
                            { firstRow }
                        </div>
                        <div className="cat_row">
                            { secondRow }
                        </div>
                    </div>
                )
            }


        }

        return null
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="cats-preview">
                        { this.renderRows() }
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(BrickCatPreview);