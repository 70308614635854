import React, {Component} from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import LazyLoad from 'react-lazyload';
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        DO_SEARCH: state.DO_SEARCH,
        PRODUCTS: state.PRODUCTS,
        CATS: state.CATS,
        TOKEN: state.TOKEN,
        PREPARED_CATS: state.PREPARED_CATS,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Catalog extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        }
    }


    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });
        DATA.push({
            'to' : '/catalog',
            'title' : 'ÐšÐ°Ñ‚Ð°Ð»Ð¾Ð³ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ†Ð¸Ð¸'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    getCatLinks = () =>
    {
        let CATS = [];

        for (let i = 0; i < this.props.CATS.length; i++) {
            let CAT = this.props.CATS[i];

            CATS.push(
                <Link to={"/" + CAT.url} className="cat_bubble" key={i}>
                    {CAT.title}
                </Link>
            )
        }

        return (
            <div className="row bubbles_holder">
                <div className="col-9">
                    <div className="cats_bubbles">
                        {CATS}
                    </div>
                </div>
            </div>
        );
    }

    renderContent = () =>
    {
        let CAT_LINKS = this.getCatLinks();

        return (
            <div>
                {CAT_LINKS}
            </div>
        );
    }

    getCatMainContent = () =>
    {
        let GROUPED_PRODUCTS = this.props.GROUPED_PRODUCTS;
        let CATS = [];

        for (let i = 0; i < this.props.CATS.length; i++) {
            let CAT = this.props.CATS[i];

            let USE_PRODUCTS = false;

            if (CAT.id > 0) {
                if (typeof GROUPED_PRODUCTS[CAT.id] !== "undefined") {
                    USE_PRODUCTS = GROUPED_PRODUCTS[CAT.id];
                }
            } else {
                if (typeof this.props.POPULAR !== "undefined") {
                    USE_PRODUCTS = this.props.POPULAR;
                }
            }

            if (USE_PRODUCTS) {

                let PRODUCTS = [];
                let WIDTH = window.innerWidth;
                let LIMIT = 6;
                if (WIDTH <= 1200) {
                    LIMIT = 4;
                }

                let USED = 0;

                for (let j = 0; j < USE_PRODUCTS.length; j++) {
                    if (USED < LIMIT) {
                        PRODUCTS.push(
                            <div className="item"  key={j}><ProductCard ID={USE_PRODUCTS[j]} /></div>
                        );

                        USED++;
                    }
                }

                CATS.push(
                    <div key={i} className="catalog_block">
                        <div className="catalog_title">
                            {CAT.title}
                        </div>
                        <div className="rm_carousel">
                            {PRODUCTS}
                        </div>
                        <Link to={"/" + CAT.url} className="catalog_link" key={i}>
                            Ð‘Ð¾Ð»ÑŒÑˆÐµ Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð²
                        </Link>
                    </div>
                );
            }
        }

        return (
            <div className="row">
                <div className="col-12">
                    {CATS}
                </div>
            </div>
        );
    }

	render() {
    	return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>ÐšÐ°Ñ‚Ð°Ð»Ð¾Ð³ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ†Ð¸Ð¸</title>
                        <meta name="description" content="ÐšÐ°Ñ‚Ð°Ð»Ð¾Ð³ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ†Ð¸Ð¸" />
                        <meta name="keywords" content="ÐšÐ°Ñ‚Ð°Ð»Ð¾Ð³ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ†Ð¸Ð¸" />
                    </Helmet>
                    {this.renderBreadCrumbs()}
                    <div id="page-content" className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="catalog__title">
                                    ÐšÐ°Ñ‚Ð°Ð»Ð¾Ð³ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ†Ð¸Ð¸
                                </h1>
                            </div>
                        </div>
                        {this.getCatLinks()}
                        {this.getCatMainContent()}
                    </div>

                    <ModalsContainer />
                </div>
            </CSSTransitionGroup>
		);
    }
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(Catalog))