export const sendPostRequest = async (data,gateway) =>
{
    const response = await fetch( 
        gateway, 
        {
            credentials: 'include',
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Accept': 'application/json'
            },
        }
    );
    const responseJson = await response.json();

    if (responseJson.status.result === 'SUCCESS') {
        return responseJson.data;
    } else {
        return false;
    }
}
