import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import E404 from './../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        DO_SEARCH: state.DO_SEARCH
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Company extends Component {

    constructor(props) {
        super(props);
        this.state = {
            URL: props.match.params.url,
            PAGE: false,
            ERROR: false,
            TOKEN: props.TOKEN
        }
    }

    componentDidMount() {
        this.props.closeAllModals();
        this.getData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.url !== prevProps.match.params.url) {
            this.props.closeAllModals();

            ///this.setState({PAGE: false, URL: this.props.match.params.url, ERROR: false});

            this.getData();
        }

    }

    checkRerender() {
        if (this.state.PAGE.url !== this.props.match.params.url) {
            this.getData();
        }
    }

    getData = async () => {
        let SEND_DATA = {
            action: 'getPageData',
            url: this.props.match.params.url,
            token: this.state.TOKEN
        };

        let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
        if (data !== false) {
            this.setState({ PAGE: data, ERROR: false });
        } else {
            this.setState({ ERROR: true });
        }
    }

    renderBreadCrumbs() {
        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });

        DATA.push({
            'to': '/' + this.state.PAGE.parent_url + '/' + this.state.PAGE.url,
            'title': this.state.PAGE.title
        });

        return <BreadCrumbs DATA={ DATA } />
    }

    render() {
        if (this.state.ERROR) {
            return <E404 />;
        }

        this.checkRerender();

        if (this.state.PAGE) {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part">
                        <Helmet>
                            <title>{ this.state.PAGE.title }</title>
                            <meta name="description" content={ this.state.PAGE.meta_description } />
                            <meta name="keywords" content={ this.state.PAGE.meta_keywords } />
                        </Helmet>

                        { this.renderBreadCrumbs() }
                        <div id="products" className="page-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="main-heading ">{ this.state.PAGE.title }</h1>
                                        <div className="company_page_content" dangerouslySetInnerHTML={ { __html: this.state.PAGE.content } }>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ModalsContainer />
                    </div>
                </CSSTransitionGroup>
            );
        } else {
            return (
                <CSSTransitionGroup
                    transitionAppear={ true }
                    transitionAppearTimeout={ 600 }
                    transitionEnterTimeout={ 600 }
                    transitionLeaveTimeout={ 200 }
                    transitionName={ 'SlideIn' }
                >
                    <div className="right-part">

                        <ModalsContainer />
                    </div>
                </CSSTransitionGroup>
            );
        }
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Company))
