import React, {Component} from 'react';
import {Switch, Route, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import InnerProduct from './../../../APP/COMPONENTS/ContentParts/InnerProduct';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS,
        CATS: state.CATS,
        PRODUCT_MODAL: state.PRODUCT_MODAL
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class InnerModalProduct extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            PRODUCT: false,
            ID: this.props.PRODUCT_MODAL
        };
    }

    componentDidMount()
    {
        this.getProduct(this.state.ID);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.PRODUCT_MODAL !== prevProps.PRODUCT_MODAL) {
            if (this.props.PRODUCT_MODAL !== false) {
                this.setState({ID: this.props.PRODUCT_MODAL});
                this.getProduct(this.props.PRODUCT_MODAL);
            }
        }
    }

    getProduct = (FIND_ID) =>
    {
        if (FIND_ID) {
            if (this.props.PRODUCTS) {
                let PRODUCT = false;

                for (let ID in this.props.PRODUCTS) {
                    if (this.props.PRODUCTS[ID].id == FIND_ID) {
                        PRODUCT = this.props.PRODUCTS[ID];
                        PRODUCT.CAT = this.getParentCat(PRODUCT.group);
                    }
                }

                if (PRODUCT) {
                    this.setState({PRODUCT: PRODUCT});
                }
            }
        }
    }

    getParentCat(id)
    {
        let CAT = false;
        for (let i = 0; i < this.props.CATS.length; i ++) {
            if (!CAT) {

                let PARENT_CAT = this.props.CATS[i];
                if (PARENT_CAT.id == id) {
                    CAT = PARENT_CAT;
                }

                if (!CAT) {
                    if (PARENT_CAT.children.length) {
                        for (let j = 0; j < PARENT_CAT.children.length; j ++) {
                            let CHILD_CAT = PARENT_CAT.children[j];
                            if (CHILD_CAT.id == id) {
                                CAT = CHILD_CAT;
                            }
                        }
                    }
                }

            }
        }

        return CAT;
    }

	render()
    {
        if (this.state.PRODUCT) {
            return <InnerProduct product={this.state.PRODUCT} />
        } else {
            return false;
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(InnerModalProduct);