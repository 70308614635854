import React, {Component} from 'react';
import {Switch, Route, withRouter, Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ModalsContainer from './../../../APP/MODALS/ModalsContainer.js';

import E404 from './../../../APP/COMPONENTS/ContentParts/E404';

import FooterBlock from './../../../APP/COMPONENTS/Footer.js';

import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs';

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        DO_SEARCH: state.DO_SEARCH,
        CATS: state.CATS,
        PRODUCTS: state.PRODUCTS
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class SiteMap extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
            TOKEN: props.TOKEN,
            CATS: props.CATS,
            PRODUCTS: props.PRODUCTS
        }
    }

    componentDidMount()
    {
        this.props.closeAllModals();
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (this.props.match.params.url !== prevProps.match.params.url) {
            this.props.closeAllModals();
         
            this.setState({PAGE: false, URL: this.props.match.params.url, ERROR: false});
        }
        if (this.props.TOKEN !== prevProps.TOKEN) {
            this.setState({TOKEN: this.props.TOKEN});
        }
        if (this.props.CATS !== prevProps.CATS) {
            this.setState({CATS: this.props.CATS});
        }
        if (this.props.PRODUCTS !== prevProps.PRODUCTS) {
            this.setState({PRODUCTS: this.props.PRODUCTS});
        }
        if (this.props.DO_SEARCH !== prevProps.DO_SEARCH) {
            if (this.props.DO_SEARCH) {
                this.props.history.push('/search/' + this.props.DO_SEARCH);
            }
        }
    }

    checkRerender()
    {
    }


    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });

        DATA.push({
            'to' : '/company/sitemap',
            'title' : 'ÐšÐ°Ñ€Ñ‚Ð° ÑÐ°Ð¹Ñ‚Ð°'
        });

        return <BreadCrumbs DATA={DATA} />
    }

	render() {
        let LINKS = [];
        let CATS = this.state.CATS;
        for (let i = 0; i < CATS.length; i++) {
                let CAT = CATS[i];
                LINKS.push(<Link key={'cat' + i} to={'/' + CAT.url} title={CAT.title} >{CAT.title}</Link>);
        }

        let PRODUCTS = this.state.PRODUCTS;
        for (let key in PRODUCTS) {
                let PRODUCT = PRODUCTS[key];
                LINKS.push(<Link key={'product' + key} to={'/' + PRODUCT.url + '.html'} title={PRODUCT.title} >{PRODUCT.title}</Link>);
        }

        return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
                <div className="right-part">
                    <Helmet>
                        <title>ÐšÐ°Ñ€Ñ‚Ð° ÑÐ°Ð¹Ñ‚Ð°</title>
                        <meta name="description" content="ÐšÐ°Ñ€Ñ‚Ð° ÑÐ°Ð¹Ñ‚Ð°"/>
                        <meta name="keywords" content="ÐšÐ°Ñ€Ñ‚Ð° ÑÐ°Ð¹Ñ‚Ð°" />
                    </Helmet>

                    {this.renderBreadCrumbs()}
                    <div id="products" className="page-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <h1 className="main-heading ">ÐšÐ°Ñ€Ñ‚Ð° ÑÐ°Ð¹Ñ‚Ð°</h1>
                                    <div className="sitemap">
                                        {LINKS}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ModalsContainer />
                </div>

            </CSSTransitionGroup>
        );
    }
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(SiteMap))
