import React, {Component} from 'react';
import {Switch, Route, Link, withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js'
import LazyLoad from 'react-lazyload';
import { sendPostRequest } from "./../../APP/HELPERS/requests"
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import ProductsScrollPreview from './../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductCard from './../../APP/COMPONENTS/ContentParts/ProductCard';
import InnerLoader from './../../APP/COMPONENTS/ContentParts/InnerLoader';
import BreadCrumbs from './../../APP/COMPONENTS/ContentParts/BreadCrumbs';

import ModalsContainer from './../../APP/MODALS/ModalsContainer.js';

import FooterBlock from './../../APP/COMPONENTS/Footer.js';

import Login from './../../APP/COMPONENTS/LKParts/Login.js';
import Auth from './../../APP/COMPONENTS/LKParts/Auth.js';

function mapStateToProps(state) {
    return {
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class PersonalPage extends Component {

	constructor(props)
    {
        super(props);
    }

    componentDidMount() {
    }

    renderH1()
    {
        return (
            <div className="row">
                <div className="col-12">
                    <h1 className="catalog__title">
                        Ð›Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚
                    </h1>
                </div>
            </div>
        );
    }

    renderBreadCrumbs()
    {
        let DATA = [];
        DATA.push({
            'to' : '/',
            'title' : 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });
        DATA.push({
            'to' : '/personal',
            'title' : 'Ð›Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚'
        });

        return <BreadCrumbs DATA={DATA} />
    }

    renderContent = () =>
    {
        if (this.props.USER) {
            return <Auth />;
        } else {
            return <Login />
        }
    }

	render() {
    	return (
            <CSSTransitionGroup
                transitionAppear={true}
                transitionAppearTimeout={600}
                transitionEnterTimeout={600}
                transitionLeaveTimeout={200}
                transitionName={'SlideIn'}
            > 
        		<div className="right-part">
                    <Helmet>
                        <title>Ð›Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚</title>
                        <meta name="description" content="Ð›Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚" />
                        <meta name="keywords" content="Ð›Ð¸Ñ‡Ð½Ñ‹Ð¹ ÐºÐ°Ð±Ð¸Ð½ÐµÑ‚" />
                    </Helmet>
                    {this.renderBreadCrumbs()}
                    <div id="page-content" className="container">
                        <div>
                            {this.renderH1()}
                        </div>

                        {this.renderContent()}
                    </div>

                    <ModalsContainer />
    			</div>
            </CSSTransitionGroup>
		);
    }
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(PersonalPage))