import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CommentForm from './../../../APP/COMPONENTS/ContentParts/CommentForm.js'
import Comments from './../../../APP/COMPONENTS/ContentParts/Comments.js'
import BreadCrumbs from './../../../APP/COMPONENTS/ContentParts/BreadCrumbs.js'
import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview.js'

function mapStateToProps(state, myProps) {
    let AMOUNT = 0;
    if (typeof state.CART[myProps.product.id] !== "undefined") {
        AMOUNT = state.CART[myProps.product.id];
    }
    return {
        AMOUNT: AMOUNT,
        GROUPED_PRODUCTS: state.GROUPED_PRODUCTS,
        POPULAR: state.POPULAR,
        FRIENDS_DISCOUNT: state.FRIENDS_DISCOUNT,
        USER: state.USER,
        PRICE_TYPE: state.PRICE_TYPE,
        CATS: state.CATS,
        FAVS: state.FAVS
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class InnerProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ID: props.product.id,
            PRODUCT: props.product,
            MANIPULATE: false,
            VIEW: 'TEXT'
        }

        this.state.PRODUCT = props.product;
        this.myRef = React.createRef();
    }

    componentWillMount() {
        this.fetchAllData();
    }

    fetchAllData = async (result) => {
        try {
            let SEND_DATA = {
                action: 'getFullProductData',
                id: this.state.PRODUCT.id
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                data.product.CAT = this.state.PRODUCT.CAT;
                this.setState({ PRODUCT: data.product });
            }
        } catch (error) {
            console.log('BAD_CARDS_STORY');
        }
    }

    componentDidMount() {
        window.dataLayer.push({
            "ecommerce": {
                "detail": {
                    "products": [
                        {
                            "id": this.state.PRODUCT.id,
                            "name": this.state.PRODUCT.title,
                            "price": this.state.PRODUCT.price
                        }
                    ]
                }
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.product.id !== prevProps.product.id) {
            this.setState({ ID: this.props.product.id, PRODUCT: this.props.product, VIEW: 'TEXT' }, () => this.fetchAllData());
            this.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    renderBadges() {
        let LEFT_ONES = [];

        if (this.state.PRODUCT.eco == 1) {
            let BADGE = (
                <div className="angle-label" key="1">
                    <img src="/system_images/icons/leaf.svg" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.ng == 1) {
            let BADGE = (
                <div className="angle-label" key="2">
                    <img src="/system_images/icons/no_bottle.svg" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.no_grain == 1) {
            let BADGE = (
                <div className="angle-label" key="3">
                    <img src="/system_images/icons/no_grain.svg" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.halal == 1) {
            let BADGE = (
                <div className="angle-label" key="4">
                    <img src="/system_images/icons/halal.svg" title="Ð¥Ð°Ð»ÑÐ»ÑŒ" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        if (this.state.PRODUCT.nobox == 1) {
            let BADGE = (
                <div className="angle-label" key="5">
                    <img src="/system_images/icons/no_box.svg" />
                </div>
            );
            LEFT_ONES.push(BADGE);
        }

        return false;


        if (LEFT_ONES.length) {
            return (
                <div className="tags">
                    { LEFT_ONES.map(badge => { return badge }) }
                </div>
            );
        } else {
            return false;
        }
    }

    renderMinusButton() {
        return (
            <a className="am-minus" onClick={ () => this.minusProduct() } tabIndex="0">
                <img src="/system_images/minus.svg" />
            </a>
        );
    }

    minusProduct() {
        if (this.props.AMOUNT - 1 < 1) {
            this.setState({ MANIPULATE: false });
        }
        this.props.minusCart(this.state.PRODUCT);
    }

    checkMaximum() {
        let PRODUCT = this.state.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    plusProduct() {
        let check = this.checkMaximum();

        if (check) {
            if (this.props.AMOUNT > 0) {
                this.props.plusCart(this.state.PRODUCT);
            } else {
                this.addToCart();
            }
        }
    }

    renderCartedPrice(price_type = false) {
        if (price_type) {
            let USE_PRICE = this.state.PRODUCT['price' + price_type];
            if (this.props.AMOUNT > 0) {
                return USE_PRICE * this.props.AMOUNT;
            } else {
                return USE_PRICE;
            }
        }

        if (this.props.FRIENDS_DISCOUNT) {
            let PRICE = this.state.PRODUCT.price - (Math.round(this.state.PRODUCT.price / 100 * 25));
            if (this.props.AMOUNT > 0) {
                return PRICE * this.props.AMOUNT;
            } else {
                return PRICE;
            }
        } else {
            if (this.props.AMOUNT > 0) {
                return this.state.PRODUCT.price * this.props.AMOUNT;
            } else {
                return this.state.PRODUCT.price;
            }
        }
    }

    addToCart() {
        this.props.addToCart(this.state.PRODUCT);
    }

    morePressed() {
        let check = this.checkMaximum();

        if (check) {
            this.props.plusCart(this.state.PRODUCT);
        }
    }

    checkMaximum() {
        let PRODUCT = this.state.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    renderCartButton = () => {
        if (this.props.AMOUNT == 0) {
            return (
                <a className="to-cart-btn" onClick={ () => this.addToCart() } >
                    <img src="/system_images/cart.png" />
                </a>
            );
        }
    }

    renderDiscountPrice = () => {



        let OLD_PRICE = 0;
        if (this.props.AMOUNT > 0) {
            OLD_PRICE = this.state.PRODUCT.old_price * this.props.AMOUNT;
        } else {
            OLD_PRICE = this.state.PRODUCT.old_price;
        }

        return OLD_PRICE;

    }

    renderPrices = () => {
        if ((this.state.PRODUCT.old_price > 0) && (this.state.PRODUCT.sale == 1)) {
            return (
                <div className="price_holder">
                    <span className="price sale">{ this.renderCartedPrice() } Ñ€</span>
                    <span className="price old_price">{ this.renderDiscountPrice() } Ñ€</span>
                    { this.props.USER && this.props.USER.price_type > 0 || this.props.PRICE_TYPE ? (
                        <div className="flex_grow"><span className="your_price">Ð’Ð°ÑˆÐ° Ñ†ÐµÐ½Ð°: <strong>{ this.renderCartedPrice(this.props.PRICE_TYPE ? this.props.PRICE_TYPE : this.props.USER.price_type) } Ñ€</strong></span></div>
                    ) : null }
                </div>
            )
        } else {
            return (
                <div className="price_holder">
                    <span className="price">{ this.renderCartedPrice() } Ñ€</span>
                    { this.props.USER && this.props.USER.price_type > 0 || this.props.PRICE_TYPE ? (
                        <div className="flex_grow"><span className="your_price">Ð’Ð°ÑˆÐ° Ñ†ÐµÐ½Ð°: <strong>{ this.renderCartedPrice(this.props.PRICE_TYPE ? this.props.PRICE_TYPE : this.props.USER.price_type) } Ñ€</strong></span></div>
                    ) : null }
                </div>
            )
        }
    }

    renderButtons = () => {
        if (this.state.PRODUCT.availableones > 0) {
            return (
                <div
                    className="button-holder" itemProp="offers" itemScope itemType="http://schema.org/Offer"
                >

                    { this.renderPrices() }
                    <div className="tocart">
                        { this.renderManipulator() }
                        { this.renderCartButton() }
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="button-holder"
                >
                </div>
            );
        }

        if (this.state.PRODUCT.availableones > 0) {
            return (
                <div className="btn_place">
                    <div className="product-adder button-holder">
                        <div className="selecter" itemProp="offers" itemScope itemType="http://schema.org/Offer">
                            { this.renderPrices() }

                            <div className="btn-cart-row">
                                { this.renderManipulator() }
                                { this.renderCartButton() }
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="btn_place">
                </div>
            );
        }
    }

    renderPlusButton() {
        return (
            <a className="am-plus" onClick={ () => this.plusProduct() } tabIndex="0">
                <img src="/system_images/plus.svg" />
            </a>
        );
    }

    rmFixed(numb) {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    renderShowAmount() {
        let PRODUCT = this.state.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;
        if (this.props.AMOUNT > 0) {
            if (PRODUCT.peramount % 1 !== 0) {
                SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
            } else {
                SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
            }
        } else {
            SHOW_AMOUNT = PRODUCT.peramount;
        }

        return SHOW_AMOUNT;
    }

    renderCurShowAmount() {
        let PRODUCT = this.state.PRODUCT;
        let SHOW_AMOUNT = this.props.AMOUNT * PRODUCT.peramount;

        if (this.props.AMOUNT < 1) {
            return PRODUCT.peramount;
        }

        if (PRODUCT.peramount % 1 !== 0) {
            SHOW_AMOUNT = this.rmFixed(SHOW_AMOUNT);
        } else {
            SHOW_AMOUNT = SHOW_AMOUNT.toFixed()
        }

        return SHOW_AMOUNT;
    }

    renderMaximumMessage() {
        let PRODUCT = this.state.PRODUCT;
        if (this.props.AMOUNT == PRODUCT.availableones) {
            return (
                <div>
                    <div className="cart-max">Ð­Ñ‚Ð¾ Ð¼Ð°ÐºÑÐ¸Ð¼Ð°Ð»ÑŒÐ½Ð¾Ðµ ÐºÐ¾Ð»Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾ Ñ‚Ð¾Ð²Ð°Ñ€Ð°.</div>
                    <div className="cart-max-text">Ð’Ñ‹ Ð¼Ð¾Ð¶ÐµÑ‚Ðµ ÑƒÐºÐ°Ð·Ð°Ñ‚ÑŒ Ð¶ÐµÐ»Ð°ÐµÐ¼Ð¾Ðµ ÐºÐ¾Ð»Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾ Ð² ÐºÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¸ Ðº Ð·Ð°ÐºÐ°Ð·Ñƒ.</div>
                </div>
            );
        }
    }

    launchManipulation() {
        if (!this.state.MANIPULATE) {
            this.setState({ MANIPULATE: true });
        }
    }

    renderManipulator = () => {
        if (this.props.AMOUNT == 0) {
            return false;
        }

        return (
            <div className="amount_controller">

                { this.renderMaximumMessage() }

                <div className="the_controls">
                    { this.renderMinusButton() }

                    <div className="the_amount">
                        <span className="current_amount">{ this.renderShowAmount() }</span>
                    </div>

                    { this.renderPlusButton() }
                </div>
            </div>
        );
    }

    renderImage() {

        if (this.state.PRODUCT.images && this.state.PRODUCT.images.length && this.state.PRODUCT.images.length > 1) {
            let SETTINGS = {
                dots: false,
                infinite: false,
                margin: 0,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                className: 'owl-product',
                arrows: true,
                swipeToSlide: true
            };

            let images = []
            this.state.PRODUCT.images.map((image, imdex) => {
                let SRC = CONFIG.API_C_DOMAIN + image.full;
                images.push(
                    <div className="item" key={ imdex } >
                        <div className="product-gallery" id="big_image" style={ { 'backgroundImage': 'url(' + SRC + ')' } }>

                        </div>
                    </div>
                )
            })

            return (
                <Slider { ...SETTINGS } className={ "product_gallery_carousel" }>
                    { images }
                </Slider>
            )

        } else {
            let SRC = this.state.PRODUCT.big;
            if ((typeof SRC !== "undefined") && (SRC !== "")) {
                SRC = CONFIG.API_C_DOMAIN + SRC;
                return (
                    <div className="the_gallery">
                        <div className="product-gallery" id="big_image" style={ { 'backgroundImage': 'url(' + SRC + ')' } }>

                        </div>
                    </div>
                );
            } else {
                let SRC = CONFIG.API_C_DOMAIN + '/imgs/no-foto.png)';
                return (
                    <div className="the_gallery">
                        <div className="product-gallery" id="big_image" style={ { 'backgroundImage': 'url(' + SRC + ')' } }>

                        </div>
                    </div>
                );
            }
        }
    }

    renderHeart = () => {
        return (
            <a className="heart_holder" onClick={ () => this.props.toggleFav(this.state.PRODUCT.id) } title="Ð˜Ð·Ð±Ñ€Ð°Ð½Ð½Ð¾Ðµ">
                { this.props.FAVS.includes(this.state.PRODUCT.id) ?
                    <img src="/system_images/heart_filled.png" />
                    :
                    <img src="/system_images/heart.png" />
                }
            </a>
        )
    }

    renderTitle() {
        return (
            <div className="product-grow">
                <h1 itemProp="name" className="product-h1">
                    <span>
                        { this.state.PRODUCT.title }
                    </span>
                </h1>
                <div className="product_amount_block">
                    { this.state.PRODUCT.price_per_one } Ñ€ / 1  { this.state.PRODUCT.ed_izm }
                </div>
            </div>
        );
    }

    renderAm() {
        return (
            <div className="the-am">
                { this.renderCurShowAmount() } { this.state.PRODUCT.ed_izm }
            </div>
        );
    }

    renderFoodBox() {
        if (this.state.PRODUCT.calories_amount && this.state.PRODUCT.calories_amount !== "") {
            return (
                <div className="foodbox">
                    <div className="foodbox-first-row">
                        <div className="foodbox-strong">
                            Ð­Ð½ÐµÑ€Ð³ÐµÑ‚Ð¸Ñ‡ÐµÑÐºÐ°Ñ Ñ†ÐµÐ½Ð½Ð¾ÑÑ‚ÑŒ
                        </div>
                        <div className="foodbox-light">
                            Ð½Ð° 100 Ð³Ñ€Ð°Ð¼Ð¼ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð°
                        </div>
                    </div>
                    <div className="foodbox-content">
                        <div className="text-right">
                            % ÐµÐ¶ÐµÐ´Ð½ÐµÐ²Ð½Ð¾Ð¹ Ð½Ð¾Ñ€Ð¼Ñ‹
                        </div>
                        <div className="foodbox-row massiv-border">
                            <div>
                                ÐšÐ°Ð»Ð¾Ñ€Ð¸Ð¹Ð½Ð¾ÑÑ‚ÑŒ: { this.state.PRODUCT.calories_amount }
                            </div>
                            <div>
                                { this.state.PRODUCT.calories_percent } %
                            </div>
                        </div>
                        <div className="foodbox-row foodbox-bordered">
                            <div>
                                Ð–Ð¸Ñ€Ñ‹ { this.state.PRODUCT.fats_amount } Ð³
                            </div>
                            <div>
                                { this.state.PRODUCT.fats_percent } %
                            </div>
                        </div>
                        <div className="foodbox-row foodbox-bordered">
                            <div>
                                Ð£Ð³Ð»ÐµÐ²Ð¾Ð´Ñ‹ { this.state.PRODUCT.ugl_amount } Ð³
                            </div>
                            <div>
                                { this.state.PRODUCT.ugl_percent } %
                            </div>
                        </div>
                        <div className="foodbox-row foodbox-bordered">
                            <div>
                                Ð‘ÐµÐ»ÐºÐ¸ { this.state.PRODUCT.bel_amount } Ð³
                            </div>
                            <div>
                                { this.state.PRODUCT.bel_percent } %
                            </div>
                        </div>
                        <div className="foodbox-row foodbox-bordered">
                            <div>
                                ÐšÐ»ÐµÑ‚Ñ‡Ð°Ñ‚ÐºÐ° { this.state.PRODUCT.klet_amount } Ð³
                            </div>
                            <div>
                                { this.state.PRODUCT.klet_percent } %
                            </div>
                        </div>
                    </div>
                    <div className="foodbox-after">
                        ÐŸÑ€Ð¾Ñ†ÐµÐ½Ñ‚ Ð´Ð½ÐµÐ²Ð½Ð¾Ð¹ Ð½Ð¾Ñ€Ð¼Ñ‹ Ð¾ÑÐ½Ð¾Ð²Ð°Ð½ Ð½Ð° 2.000 ÐºÐ°Ð»Ð¾Ñ€Ð¸Ð¹
                    </div>
                </div>
            );
        }
    }

    renderDesktopFoodbox() {
        if (this.state.PRODUCT.calories_amount !== "") {
            return (
                <div className="hidden-mobile">
                    { this.renderFoodBox() }
                </div>
            );
        }
    }

    switchView = (VIEW) => {
        if (this.state.VIEW !== VIEW) {
            this.setState({ VIEW: VIEW });
        }
    }

    renderBigButtons() {
        return (
            <div className="action-block">
                <div>
                    <a onClick={ () => this.switchView('TEXT') } className={ (this.state.VIEW !== 'TEXT') ? 'inactive' : 'active' }>
                        <img src="/system_images/copy.png" />
                        <span>ÐžÐ¿Ð¸ÑÐ°Ð½Ð¸Ðµ</span>
                    </a>
                </div>
                <div>
                    <a onClick={ () => this.switchView('LEAVE') } className={ (this.state.VIEW !== 'LEAVE') ? 'inactive' : 'active' }>
                        <img src="/system_images/comment.png" />
                        <span>ÐšÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹</span>
                    </a>
                </div>
                <div>
                    <a onClick={ () => this.switchView('COMMENTS') } className={ (this.state.VIEW !== 'COMMENTS') ? 'inactive' : 'active' }>
                        <img src="/system_images/comments.png" />
                        <span>ÐžÑ‚Ð·Ñ‹Ð²Ñ‹</span>
                    </a>
                </div>
            </div>
        );
    }

    renderSostav = () => {
        if (this.state.PRODUCT.sostav !== '') {
            return (
                <div>
                    <div className="mobile_heighter"></div>
                    <div className="in-card-label">ÐžÐ¿Ð¸ÑÐ°Ð½Ð¸Ðµ</div>
                    <div className="short-desc" itemProp="description">
                        { this.state.PRODUCT.short_description }
                    </div>
                    { this.renderSostav() }
                </div>
            );
        }
    }

    renderKZHBUrow = (title, values) => {
        if (values !== "") {
            return (
                <div className="kzhbu_row">
                    <span>{ title }</span>
                    <span>{ values }</span>
                </div>
            );
        }
    }

    renderKZHBU = () => {
        let DATA = '';
        if (
            (this.state.PRODUCT.calories_amount && this.state.PRODUCT.calories_amount !== "")
            ||
            (this.state.PRODUCT.fats_amount && this.state.PRODUCT.fats_amount !== "")
            ||
            (this.state.PRODUCT.ugl_amount && this.state.PRODUCT.ugl_amount !== "")
            ||
            (this.state.PRODUCT.bel_amount && this.state.PRODUCT.bel_amount !== "")
        ) {
            DATA = (
                <div>
                    <div className="mobile_heighter"></div>
                    <div className="in-card-label">ÐŸÐ¸Ñ‰ÐµÐ²Ð°Ñ Ñ†ÐµÐ½Ð½Ð¾ÑÑ‚ÑŒ</div>
                    <div className="short-desc" itemProp="description">
                        <div className="kzhbu_holder">
                            { this.renderKZHBUrow('ÐºÐšÐ°Ð»', this.state.PRODUCT.calories_amount) }
                            { this.renderKZHBUrow('Ð–Ð¸Ñ€Ñ‹', this.state.PRODUCT.fats_amount) }
                            { this.renderKZHBUrow('Ð‘ÐµÐ»ÐºÐ¸', this.state.PRODUCT.bel_amount) }
                            { this.renderKZHBUrow('Ð£Ð³Ð»ÐµÐ²Ð¾Ð´Ñ‹', this.state.PRODUCT.ugl_amount) }

                        </div>
                    </div>

                </div>
            );
        }

        return DATA
    }

    renderWare = () => {
        let DATA = '';

        if (this.state.PRODUCT.srok && this.state.PRODUCT.srok !== "") {
            DATA = (
                <div>
                    <div className="mobile_heighter"></div>
                    <div className="in-card-label">Ð¡Ñ€Ð¾Ðº Ð³Ð¾Ð´Ð½Ð¾ÑÑ‚Ð¸</div>
                    <div className="short-desc" itemProp="description">
                        { this.state.PRODUCT.srok }
                    </div>

                </div>
            );
        }

        return DATA
    }

    renderTextView() {
        let SHORT_DESC = '';
        let SOSTAV = '';

        if ((this.state.PRODUCT.short_description && this.state.PRODUCT.short_description !== '') || (this.state.PRODUCT.description && this.state.PRODUCT.description !== '')) {
            SHORT_DESC = (
                <div>
                    <div className="mobile_heighter"></div>
                    <div className="in-card-label">ÐžÐ¿Ð¸ÑÐ°Ð½Ð¸Ðµ</div>
                    <div className="short-desc" itemProp="description">
                        { this.state.PRODUCT.short_description !== '' ? this.state.PRODUCT.short_description : <div dangerouslySetInnerHTML={ { __html: this.state.PRODUCT.description } } /> }
                    </div>

                </div>
            );
        }

        if (this.state.PRODUCT.sostav && this.state.PRODUCT.sostav !== '') {
            SOSTAV = (
                <div>
                    <div className="mobile_heighter"></div>
                    <div className="in-card-label">Ð¡Ð¾ÑÑ‚Ð°Ð²</div>
                    <div className="short-desc" itemProp="description">
                        { this.state.PRODUCT.sostav }
                    </div>
                </div>
            );
        }

        return (
            <div className="flex-grow">
                { SHORT_DESC }
                { SOSTAV }
                { this.renderKZHBU() }
                { this.renderWare() }
            </div>
        );
    }

    renderCommentForm() {
        return (
            <CommentForm ID={ this.state.PRODUCT.id } />
        );
    }

    renderComments() {
        return (
            <Comments ID={ this.state.PRODUCT.id } />
        );
    }

    renderView() {
        if (this.state.VIEW == 'COMMENTS') {
            return this.renderComments();
        } else if (this.state.VIEW == 'LEAVE') {
            return this.renderCommentForm();
        } else {
            return this.renderTextView();
        }
    }

    renderMobileFoodbox() {
        if (this.state.PRODUCT.calories_amount !== "") {
            return (
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-6 visible-mobile">
                        { this.renderFoodBox() }
                    </div>
                </div>
            );
        }
    }

    renderFullDesc() {
        return (
            <div className="desc-h">
                <div className="main-container inner container">
                    <div className="row">
                        <div className="col-md-12 product-desc" dangerouslySetInnerHTML={ { __html: this.state.PRODUCT.description } }>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderViewMore() {
        let IMAGE = false;
        if ((this.state.PRODUCT.CAT.image !== '') && (this.state.PRODUCT.CAT.image !== null)) {
            IMAGE = <img src={ CONFIG.API_C_DOMAIN + '/imgs/groups/' + this.state.PRODUCT.CAT.image } />;
        }
        return (
            <div id="flex-grow" className="mb">
                <span className="in-card-label mt">Ðš Ñ€Ð°Ð·Ð´ÐµÐ»Ñƒ</span>
                <Link to={ "/" + this.state.PRODUCT.CAT.url } className="cat_preview big still_big" style={ { backgroundImage: 'url(' + CONFIG.API_C_DOMAIN + '/imgs/groups/' + this.state.PRODUCT.CAT.image + ')' } }>
                    <span>{ this.state.PRODUCT.CAT.title }</span>
                </Link>
            </div>
        );
    }

    renderBreadcrumbs() {
        let DATA = [];
        DATA.push({
            'to': '/',
            'title': 'Ð“Ð»Ð°Ð²Ð½Ð°Ñ'
        });

        if (this.state.PRODUCT.CAT.parent) {
            let CAT = this.props.CATS.filter(cat => {
                return cat.id == this.state.PRODUCT.CAT.parent.id
            })
            if (CAT.length > 0) {
                DATA.push({
                    'to': '/' + CAT[0].url,
                    'title': CAT[0].title
                });
            }
        }


        DATA.push({
            'to': '/' + this.state.PRODUCT.CAT.url,
            'title': this.state.PRODUCT.CAT.title
        });
        DATA.push({
            'to': '/' + this.state.PRODUCT.url,
            'title': this.state.PRODUCT.title
        });

        return <BreadCrumbs DATA={ DATA } />
    }

    renderRecommend() {
        return (
            <section className="inproduct_carousel">
                <ProductsScrollPreview CAT={ this.state.PRODUCT.CAT } IDS={ this.props.GROUPED_PRODUCTS[this.state.PRODUCT.CAT.id] } />
            </section>
        );
    }

    renderPopular() {
        return (
            <section className="inproduct_carousel">
                <ProductsScrollPreview CAT={ { id: 0, title: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾Ðµ', url: 'popular' } } IDS={ this.props.POPULAR } />
            </section>
        );
    }

    renderLastText() {
        return (
            <div className="p-text-block">
                <div className="main-container inner container">
                    <div className="row">
                        <div className="col-md-12 product-desc">
                            ÐžÑ‚Ð¾Ð±Ñ€Ð°Ð¶Ð°ÐµÐ¼Ð°Ñ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ñ Ð¾ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ðµ Ð¸Ð»Ð¸ ÑƒÐ¿Ð°ÐºÐ¾Ð²ÐºÐµ Ð¼Ð¾Ð¶ÐµÑ‚ Ð±Ñ‹Ñ‚ÑŒ Ð½ÐµÐ²ÐµÑ€Ð½Ð¾Ð¹ Ð¸Ð»Ð¸ Ð½ÐµÐ¿Ð¾Ð»Ð½Ð¾Ð¹. Ð’ÑÐµÐ³Ð´Ð° Ð¾Ð±Ñ€Ð°Ñ‰Ð°Ð¹Ñ‚ÐµÑÑŒ Ðº Ñ„Ð¸Ð·Ð¸Ñ‡ÐµÑÐºÐ¾Ð¼Ñƒ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ñƒ Ð´Ð»Ñ Ð¿Ð¾Ð»ÑƒÑ‡ÐµÐ½Ð¸Ñ Ð½Ð°Ð¸Ð±Ð¾Ð»ÐµÐµ Ñ‚Ð¾Ñ‡Ð½Ð¾Ð¹ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸Ð¸ Ð¸ Ð¿Ñ€ÐµÐ´ÑƒÐ¿Ñ€ÐµÐ¶Ð´ÐµÐ½Ð¸Ð¹. Ð—Ð° Ð´Ð¾Ð¿Ð¾Ð»Ð½Ð¸Ñ‚ÐµÐ»ÑŒÐ½Ð¾Ð¹ Ð¸Ð½Ñ„Ð¾Ñ€Ð¼Ð°Ñ†Ð¸ÐµÐ¹ Ð¾Ð±Ñ€Ð°Ñ‰Ð°Ð¹Ñ‚ÐµÑÑŒ Ðº Ð¿Ñ€Ð¾Ð´Ð°Ð²Ñ†Ñƒ Ð¸Ð»Ð¸ Ð¿Ñ€Ð¾Ð¸Ð·Ð²Ð¾Ð´Ð¸Ñ‚ÐµÐ»ÑŽ. * Ð’ÐµÑ Ð½ÐµÐºÐ¾Ñ‚Ð¾Ñ€Ñ‹Ñ… Ñ‚Ð¾Ð²Ð°Ñ€Ð¾Ð², Ð½Ð°Ð¿Ñ€Ð¸Ð¼ÐµÑ€ Ð¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð², Ð¸Ð·Ð¼ÐµÑ€ÑÐµÑ‚ÑÑ Ð¿Ð¾ÐºÑƒÐ¿Ð°Ñ‚ÐµÐ»ÑÐ¼Ð¸ Ñ Ð¿Ð¾Ð¼Ð¾Ñ‰ÑŒÑŽ Ð²ÐµÑÐ¾Ð² Ð² Ð¿Ñ€Ð¾Ñ…Ð¾Ð´Ðµ. Ð’ÐµÑ Ð¿Ñ€ÐµÐ´Ð¼ÐµÑ‚Ð¾Ð² Ð¸Ð· Ð¿Ñ€Ð¸Ð»Ð°Ð²ÐºÐ¾Ð² Ð³Ð°ÑÑ‚Ñ€Ð¾Ð½Ð¾Ð¼Ð°, Ð¼ÑÑÐ° Ð¸ Ð¼Ð¾Ñ€ÐµÐ¿Ñ€Ð¾Ð´ÑƒÐºÑ‚Ð¾Ð² Ð¾Ð¿Ñ€ÐµÐ´ÐµÐ»ÑÐµÑ‚ÑÑ ÑÑ‚Ð¸ÐºÐµÑ‚ÐºÐ¾Ð¹, Ð½Ð°Ð½ÐµÑÐµÐ½Ð½Ð¾Ð¹ Ð² Ð¼Ð°Ð³Ð°Ð·Ð¸Ð½Ðµ Ð½Ð° ÑÑ‚Ð¸ Ð¿Ñ€ÐµÐ´Ð¼ÐµÑ‚Ñ‹.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderRecRow() {
        let CAT = { id: 0, title: 'Ð ÐµÐºÐ¾Ð¼ÐµÐ½Ð´ÑƒÐµÐ¼:', url: 'popular' };
        let products = this.props.POPULAR;

        return (
            <div className="no_white">
                <ProductsScrollPreview CAT={ CAT } IDS={ products } />
            </div>
        );
    }

    renderNewBadge = () => {
        if (this.state.PRODUCT.new_product == 1) {
            return (
                <div className="discount_percent new">
                    ÐÐ¾Ð²Ð¸Ð½ÐºÐ°
                </div>
            )
        }

    }

    renderDiscountPercent = () => {
        if ((this.state.PRODUCT.sale == 1) && (this.state.PRODUCT.old_price > 0)) {
            let PERCENT = (100 - (this.state.PRODUCT.price / (this.state.PRODUCT.old_price / 100))).toFixed();
            return (
                <div className="discount_percent">
                    -{ PERCENT }%
                </div>
            )
        }

    }

    renderUpdatedBadges = () => {
        let badges = []

        if (this.state.PRODUCT.hot == 1) {
            badges.push(
                <div key="hot" className="discount_percent hot">
                    ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾
                </div>
            )
        }

        if ((this.state.PRODUCT.sale == 1) && (this.state.PRODUCT.old_price > 0)) {
            let PERCENT = (100 - (this.state.PRODUCT.price / (this.state.PRODUCT.old_price / 100))).toFixed();
            badges.push(
                <div key="discount" className="discount_percent">
                    -{ PERCENT }%
                </div>
            )
        }

        if (this.state.PRODUCT.new_product == 1) {
            badges.push(
                <div key="new" className="discount_percent new">
                    ÐÐ¾Ð²Ð¸Ð½ÐºÐ°
                </div>
            )
        }

        if (this.state.PRODUCT.premium == 1) {
            badges.push(
                <div key="new" className="discount_percent premium">
                    ÐŸÑ€ÐµÐ¼Ð¸ÑƒÐ¼
                </div>
            )
        }

        if (this.state.PRODUCT.exclusive == 1) {
            badges.push(
                <div key="trend" className="discount_percent trend">
                    Ð’ Ñ‚Ñ€ÐµÐ½Ð´Ðµ
                </div>
            )
        }



        if (badges.length) {
            return (
                <div className="badges_holder">
                    { badges }
                </div>
            )
        }
    }

    render() {
        return (
            <>
                <section ref={ this.myRef }>
                    <div className="big_product_holder">
                        { this.renderBreadcrumbs() }
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="product_paged">
                                        <div className="the_detailed" itemScope itemType="http://schema.org/Product">
                                            { this.renderTitle() }

                                            { this.renderButtons() }
                                            <div className="inner_product_separator" />
                                            <div className="inner_text_block">
                                                { this.renderTextView() }
                                            </div>
                                        </div>
                                        <div className="images-block">
                                            <div className="pid_block">{ this.state.PRODUCT.id }</div>
                                            { this.renderUpdatedBadges() }
                                            { this.renderImage() }
                                            { this.renderHeart() }
                                        </div>
                                    </div>
                                    <div className="comments_all">
                                        { this.renderCommentForm() }
                                        { this.renderComments() }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
                { this.renderRecRow() }
            </>
        );
    }

}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InnerProduct)
