import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import LazyLoad from 'react-lazyload';

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductsScrollPreview from './../../../APP/COMPONENTS/ContentParts/ProductsScrollPreview';
import ProductsStaticPreview from './../../../APP/COMPONENTS/ContentParts/ProductsStaticPreview';

function mapStateToProps(state) {
    return {
        CATS: state.CATS,
        REGION: state.REGION,
        TOKEN: state.TOKEN,
        PREPARED_CATS: state.PREPARED_CATS,
        POPULAR: state.POPULAR
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CatPreview extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            REGION: props.REGION,
            CATS: props.CATS,
            PREPARED_CATS: []
        };

        this.LOADING = false;
        this.LOADED = {};
    }

    componentDidMount() {
        this.getCatsAndIds();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.REGION !== prevProps.REGION)) {
            this.LOADING = false;
            this.LOADED = {};
            this.setState({ CATS: this.props.CATS, REGION: this.props.REGION, PREPARED_CATS: [] });
            this.getCatsAndIds();
        }

    }

    getCatsAndIds = async () => {
        if (this.state.PREPARED_CATS.length == 0) {
            if (!this.LOADING) {
                this.LOADING = true;
                if (this.state.CATS) {

                    if (this.props.ID == -3) {
                        if (!this.LOADED[-3]) {
                            let CAT = { id: -3, title: 'ÐÐºÑ†Ð¸Ð¸', url: 'discounts' };
                            let products = await this.getCatData(CAT);
                            if (products) {
                                this.setState({ PREPARED_CATS: this.state.PREPARED_CATS.concat({ CAT: CAT, IDS: products }) });
                                this.LOADED[-3] = true;
                            }
                        }
                    }

                    if (this.props.ID == -1) {
                        if (!this.LOADED[-1]) {
                            let CAT = { id: -1, title: 'ÐÐ¾Ð²Ð¸Ð½ÐºÐ¸', url: 'new_products' };
                            let products = await this.getCatData(CAT);
                            if (products) {
                                this.setState({ PREPARED_CATS: this.state.PREPARED_CATS.concat({ CAT: CAT, IDS: products }) });
                                this.LOADED[-1] = true;
                            }
                        }
                    }

                    if (this.props.ID == -2) {
                        if (!this.LOADED[-2]) {
                            let CAT = { id: -2, title: 'Ð’ Ñ‚Ñ€ÐµÐ½Ð´Ðµ', url: 'trends' };
                            let products = await this.getCatData(CAT);
                            if (products) {
                                this.setState({ PREPARED_CATS: this.state.PREPARED_CATS.concat({ CAT: CAT, IDS: products }) });
                                this.LOADED[-2] = true;
                            }
                        }
                    }

                    if (this.props.ID == 0) {
                        if (!this.LOADED[0]) {
                            let CAT = { id: 0, title: 'ÐŸÐ¾Ð¿ÑƒÐ»ÑÑ€Ð½Ð¾Ðµ', url: 'popular' };
                            let products = await this.getCatData(CAT);
                            if (products) {
                                this.setState({ PREPARED_CATS: this.state.PREPARED_CATS.concat({ CAT: CAT, IDS: products }) });
                                this.LOADED[0] = true;
                            }
                        }
                    }

                    this.props.setPreparedCats(this.state.PREPARED_CATS);
                }
            }
        }
    }


    getCatData = async (CAT) => {
        try {
            let SEND_DATA = {
                action: 'getCarouselForCat',
                id: CAT.id,
                REGION: this.state.REGION,
                AB_CASE: window.AB_CASE,
                token: this.props.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);

            if (data !== false) {
                return data;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    renderRows = () => {
        this.getCatsAndIds();

        if (this.state.PREPARED_CATS) {
            let RENDERED_CATS = [];

            for (let i = 0; i < this.state.PREPARED_CATS.length; i++) {
                let OBJ = this.state.PREPARED_CATS[i];
                let CLASS = '';
                // if (OBJ.CAT.id == -3) {
                //     CLASS = 'discount';
                // } else if (OBJ.CAT.id == -1) {
                //     CLASS = 'exclusive';
                // } else if (OBJ.CAT.id == -2) {
                //     CLASS = 'newp';
                // }
                RENDERED_CATS.push(<LazyLoad key={ i } height={ 300 }><ProductsScrollPreview CLASS={ CLASS } CAT={ OBJ.CAT } IDS={ OBJ.IDS } /></LazyLoad>)
            }

            return (
                <div>
                    { RENDERED_CATS.map(carousel => carousel) }
                </div>
            );
        }
    }

    render() {
        return (
            <div>
                { this.renderRows() }
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CatPreview);