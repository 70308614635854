import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"
import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import Bonuses from "./../../../APP/COMPONENTS/ContentParts/Bonuses"
import History from "./../../../APP/COMPONENTS/ContentParts/History"
import Personal from "./../../../APP/COMPONENTS/ContentParts/Personal"
import MobileLatestOrders from "./../../../APP/COMPONENTS/ContentParts/MobileLatestOrders"

function mapStateToProps(state, myProps) {
    return {
        TOKEN: state.TOKEN,
        USER: state.USER
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Auth extends Component {

	constructor(props)
    {
        super(props);

        let VIEW = 'NAVS';
        if (WIDTH > 760) {
            VIEW = 'HISTORY';
        }

        if ((props.VIEW !== true) && (props.VIEW !== false)) {
            VIEW = props.VIEW;
        }

        this.state = {
        	PHONE: '+7-999-999-99-99',
        	VIEW: VIEW,
        	SENT: false,
        	TOKEN: this.props.TOKEN,
        	USER: this.props.USER,
            ACTIVE_STARS:   0,
            LEVEL:          0,
            LEFT:           0
        }
    }

    componentDidMount()
    {
        this.updateUserInfo();
    }

    updateUserInfo = async() =>
    {
        try {
            let SEND_DATA = {
                action:     'updateUserInfo',
                apikey:      this.props.USER.TOKEN
            };

            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
                this.props.doAuth(data.user);
                this.getTheImageAndTitle();
            }
        } catch (error) {
            console.warn('PROBLEM WHILE LOGGING IN');
        }
    }

	render() {

		return (
            <div>
                <div className="login_form_block form-fields">
                    <Personal />
                </div>
                <div className="login_form_block form-fields">
                    <History />
                </div>

                <a className="logout_button" onClick={() => this.props.logOut()}>Ð’Ñ‹Ñ…Ð¾Ð´ Ð¸Ð· Ð°ÐºÐºÐ°ÑƒÐ½Ñ‚Ð°</a>
            </div>
        );
	}

}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Auth)
