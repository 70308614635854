import React, { Component } from 'react';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Slider from "react-slick";

function mapStateToProps(state) {
    return {
        TOKEN: state.TOKEN
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class Comments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ID: props.ID,
            COMMENTS: false
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);

        this.handleScroll = this.handleScroll.bind(this);
    }

    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    componentDidMount() {
        this.getGetAndRenderComments();
    }

    handleScroll(event) {

    }

    getGetAndRenderComments = async () => {
        try {
            let SEND_DATA = {
                action: 'productComments',
                id: this.state.ID
            };
            let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
            if (data !== false) {
                this.renderComments(data.feedbacks);
            }
        } catch (error) {
            console.log('BAD_CARDS_STORY');
        }
    }

    renderComments(comments) {

        let COMMENTS = [];
        for (let i in comments) {
            let COMMENT_DATA = comments[i];

            if (typeof COMMENT_DATA !== "undefined") {
                let COMMENT = (
                    <div className="p-comment" key={ i }>
                        <div className="comment_holder">
                            <div className="comment-info">
                                { COMMENT_DATA.NAME }
                            </div>
                            <div className="comment">
                                { COMMENT_DATA.COMMENT }
                            </div>
                        </div>
                    </div>
                );
                COMMENTS.push(COMMENT);
            }
        }

        if (COMMENTS.length === 0) {
            COMMENTS = (
                <div className="comment_holder">
                    <div className="comment-info">
                        ÐÐ° Ñ‚ÐµÐºÑƒÑ‰Ð¸Ð¹ Ð¼Ð¾Ð¼ÐµÐ½Ñ‚ Ð¾Ñ‚Ð·Ñ‹Ð²Ñ‹ Ð½Ð¸ÐºÑ‚Ð¾ Ð½Ðµ Ð¾ÑÑ‚Ð°Ð²Ð»ÑÐ»!
                    </div>
                </div>
            )
        }

        let CAROUSEL = false;
        // if (COMMENTS.length > 1) {
        //     let arrows = false;
        //     if (COMMENTS.length > 1) {
        //         arrows = true;
        //     }

        //     let SETTINGS = {
        //         dots: false,
        //         infinite: true,
        //         speed: 500,
        //         slidesToShow: 1,
        //         slidesToScroll: 1,
        //         className: 'comments-slick',
        //         arrows: arrows,
        //         swipeToSlide: true
        //     };

        //     CAROUSEL = (
        //         <Slider ref={c => (this.slider = c)} {...SETTINGS}>
        //             {COMMENTS}
        //         </Slider>
        //     );
        // } else {
        CAROUSEL = (
            <div className="comments_listing" ref={ c => (this.slider = c) }>
                { COMMENTS }
            </div>
        );
        //}

        this.setState({ COMMENTS: CAROUSEL });
    }

    render() {
        return (
            <div id="flex-grow">
                <span className="in-card-label mt">ÐžÑ‚Ð·Ñ‹Ð²Ñ‹</span>
                { this.state.COMMENTS }
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Comments);