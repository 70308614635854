import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CONFIG from './../../../../SYSTEM/Config.js'
import { CSSTransition, TransitionGroup, CSSTransitionGroup } from 'react-transition-group';

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function mapStateToProps(state, myProps) {
    return {
        CATS: state.CATS,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MainNavs extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="main_navs" >
                <Link to="/company/delivery" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); this.props.hideNavs() } }>
                    Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¸ Ð¾Ð¿Ð»Ð°Ñ‚Ð°
                </Link>
                <Link to="/company/info" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); this.props.hideNavs() } }>
                    Ðž Ð½Ð°Ñ
                </Link>
                <div className="mobile_navs_separator"></div>
                <Link to="/company/partnership" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); this.props.hideNavs() } }>
                    Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾
                </Link>
                <Link to="/company/politika-konfidencialnosti" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); this.props.hideNavs() } }>
                    ÐŸÐ¾Ð»Ð¸Ñ‚Ð¸ÐºÐ° ÐºÐ¾Ð½Ñ„Ð¸Ð´ÐµÐ½Ñ†Ð¸Ð°Ð»ÑŒÐ½Ð¾ÑÑ‚Ð¸
                </Link>
                <Link to="/company/oferta" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); this.props.hideNavs() } }>
                    ÐŸÑƒÐ±Ð»Ð¸Ñ‡Ð½Ð°Ñ Ð¾Ñ„ÐµÑ€Ñ‚Ð°
                </Link>
                <Link to="/company/contacts" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); this.props.hideNavs() } }>
                    ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹
                </Link>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainNavs)
