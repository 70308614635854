import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import OrderCard from './../../../APP/COMPONENTS/ContentParts/OrderCard';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        LOADED_ORDER: state.LOADED_ORDER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class History extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            TOTAL_ORDERS: 0,
            ORDERS: [],
            START: 0
        };
    }

    componentDidMount()
    {
        this.getOrders();
        if (this.props.LOADED_ORDER) {
            this.props.doPay(this.props.LOADED_ORDER);
            this.props.setLoadOrder(false);
        }
    }

    getOrders = async () =>
    {
        let ORDERS = this.state.ORDERS;
        let START = this.state.START;

        try {
            let SEND_DATA = {
                action:     'getOrders',
                start:      START,
                apikey:     this.props.USER.TOKEN,

            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            console.log(data);
            if (data !== false) {
                this.setState({
                    ORDERS: ORDERS.concat(data.orders),
                    START: START + 20,
                    LOADED: true,
                    TOTAL_ORDERS: data.total
                });
            }
        } catch (error) {
            console.log('BAD_ORDERS_LAZY');
        }
    }

    renderOrders()
    {
        let ITEMS = [];
        for (let i = 0; i < this.state.ORDERS.length; i++) {
            ITEMS.push(<OrderCard key={i} order={this.state.ORDERS[i]} />)
        }

        return (
            <div className="lk-order">
                <div className="order-row table-header">
                    <div className="padder"></div>
                    <div className="order-id"> ÐÐ¾Ð¼ÐµÑ€ Ð·Ð°ÐºÐ°Ð·Ð° </div>
                    <div className="order-date"> Ð”Ð°Ñ‚Ð° Ð·Ð°ÐºÐ°Ð·Ð° </div>
                    <div className="order-status"> Ð¡Ñ‚Ð°Ñ‚ÑƒÑ Ð·Ð°ÐºÐ°Ð·Ð° </div>
                    <div className="order-sum"> Ð¡ÑƒÐ¼Ð¼Ð° </div>
                    <div className="padder"></div>
                </div>
                {ITEMS}
            </div>
        );
    }

    num_title = (n, titles) =>
    {
        return titles[(n % 10 === 1 && n % 100 !== 11) ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
    }

    renderMore()
    {
        if (this.state.TOTAL_ORDERS > this.state.ORDERS.length) {
            return (
                <div className="col-12 text-center the_load">
                    <a className="orange-button" onClick={() => this.getOrders()}>
                    <i className="fa fa-plus"></i> ÐŸÐ¾ÐºÐ°Ð·Ð°Ñ‚ÑŒ ÐµÑ‰Ñ‘</a>
                </div>
            );
        }
    }

    render()
    {
        if (!this.state.ORDERS) {
            return false;
        }

        return (
            <div id="history" className="inside active">
                {this.renderOrders()}
                {this.renderMore()}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(History);