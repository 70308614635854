import React, { Component } from 'react';
import { Switch, Route, withRouter, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        PRODUCTS: state.PRODUCTS
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class SearchBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            SUGGESTIONS: false,
            RESULTS: false,
            STRING: ''
        }

        this.setHistoryRef = this.setHistoryRef.bind(this);
        this.setSearchRef = this.setSearchRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.focus !== prevProps.focus) {
            if (this.props.focus == true) {
                this.searchInput.focus()
            }
        }

        if (this.props.reset !== prevProps.reset) {
            this.setState({ STRING: '', SUGGESTIONS: false, RESULTS: false })
            this.searchInput.value = ''
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setHistoryRef(node) {
        this.history = node;
    }

    setSearchRef(node) {
        this.search = node;
    }

    handleClickOutside(event) {
        if (this.history && !this.history.contains(event.target)) {
            if (this.state.SUGGESTIONS) {
                this.setState({ SUGGESTIONS: false });
            }
        }
        if (this.search && !this.search.contains(event.target)) {
            if (this.state.SHOW_SEARCH) {
                this.setState({ SHOW_SEARCH: false });
            }
        }
    }

    somethingInputted = (string) => {
        let STRING = string.target.value;
        this.setState({ STRING: STRING }, () => this.startSearch());
    }

    filterProducts = async (text) => {
        if (this.props.PRODUCTS) {
            try {
                let SEND_DATA = {
                    action: 'searchString',
                    string: text
                };

                let data = await sendPostRequest(SEND_DATA, CONFIG.API_GATE);
                if (data !== false) {
                    if (data.results.length) {
                        this.setSearchResults(data.results, text);
                    } else {
                        this.setState({ RESULTS: [], SUGGESTIONS: false, STRING: text });
                    }
                } else {
                    this.setState({ RESULTS: [], SUGGESTIONS: false, STRING: text });
                }
            } catch (error) {
                this.setState({ RESULTS: [], SUGGESTIONS: false, STRING: text });
            }

        }
    }

    setSearchResults(IDS, text) {
        let PRODUCTS = this.props.PRODUCTS;
        let FOUND = [];
        let COMPONENT = this;

        let MAX = 10;
        for (let i = 0; i < IDS.length; i++) {
            let ID = IDS[i];
            if (typeof PRODUCTS[ID] !== "undefined") {
                let PRODUCT = PRODUCTS[ID];
                if (FOUND.length <= MAX) {
                    FOUND.push(
                        <Link onClick={ () => this.setState({ SUGGESTIONS: false }) } key={ PRODUCT.id } className="search-result-row" to={ '/' + PRODUCT.url }>
                            { PRODUCT.title }
                        </Link>
                    );
                }
            }
        }


        this.setState({ RESULTS: FOUND, SUGGESTIONS: true, STRING: text });
    }

    onFocus = () => {
        this.setState({ SUGGESTIONS: true });
    }

    onFocusOut = () => {
        this.setState({ SUGGESTIONS: false });
    }

    renderResults() {
        if (this.state.SUGGESTIONS) {
            if (this.state.RESULTS) {
                return (
                    <div className="search" ref={ this.setHistoryRef }>
                        { this.state.RESULTS }
                    </div>
                );
            }
        }
    }

    renderReset() {
        if (this.state.STRING.length > 0) {
            return (
                <a className="search-controls-btn" onClick={ () => this.stopSearch() } ><i className="fas fa-times"></i></a>
            );
        }
    }

    stopSearch() {
        this.setState({ RESULTS: false, SUGGESTIONS: false, STRING: '' });
    }

    handleSearch = (e) => {
        if (e.key === 'Enter') {
            this.startSearch()
        }
    }

    startSearch() {
        this.props.doSearch(this.state.STRING);
        if (!this.props.history.location.pathname.includes('search')) {
            this.props.history.push('/search');
        }
        ///document.location.href = '/search/' + this.state.STRING;
    }

    getClass = () => {
        if (this.state.SHOW_SEARCH) {
            return 'searching visible';
        } else {
            return 'searching';
        }
    }

    toggleSearch = () => {
        let SET = true;
        if (this.state.SHOW_SEARCH) {
            SET = false;
        }

        this.setState({ SHOW_SEARCH: SET });
    }

    render() {
        return (
            <div className="search_holder" ref={ this.setSearchRef }>
                <div className={ this.getClass() }>
                    <a className="search-btn"><img src="/system_images/search.png" /></a>
                    <input
                        ref={ (input) => { this.searchInput = input; } }
                        onKeyDown={ this.handleSearch }
                        className="searchinput"
                        type="text"
                        placeholder="ÐŸÐ¾Ð¸ÑÐº"
                        onChange={ (text) => this.somethingInputted(text) }
                    />
                    { this.renderResults() }
                </div>
            </div>
        );
    }

}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchBlock))
