import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}


class Copyright extends Component {

	constructor(props)
    {
        super(props);
        this.state = {
        }
    }

	render() {
		let TODAY = new Date();
		let YEAR = TODAY.getFullYear();

    	return (
    		<div className="copyright">
				Â© {YEAR} ÐžÐžÐž "Ð­ÐºÐ¾Ð¼Ð°Ñ€ÐºÐµÑ‚"
			</div>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(Copyright)
