import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import Slider from "react-slick";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function mapStateToProps(state) {
    return {
        USER: state.USER,
        SLIDES: state.SLIDES,
        TOKEN: state.TOKEN,
        REGION: state.REGION
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

const WIDTH = window.innerWidth;

class Adverts extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            REGION: props.REGION,
            USER: props.USER
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.USER !== prevProps.USER) {
            this.setState({ USER: this.props.USER });
        }
        if (this.props.REGION !== prevProps.REGION) {
            this.setState({ REGION: this.props.REGION });
        }
    }

    doShowSlide = (SLIDE) => {
        let date = new Date;
        let hour = date.getHours();

        if ((parseInt(SLIDE.time_start) > parseInt(hour)) || (parseInt(SLIDE.time_end) < parseInt(hour))) {
            return false;
        }

        if (SLIDE.auth == 1) {
            if (!this.state.USER) {
                return false;
            }
            if (SLIDE.orders_count > 0) {
                switch (SLIDE.orders_count) {
                    case 1:
                        if ((this.state.USER.orders_count < 1) || (this.state.USER.orders_count > 5)) { return false; }
                        break;
                    case 2:
                        if ((this.state.USER.orders_count < 6) || (this.state.USER.orders_count > 10)) { return false; }
                        break;
                    case 3:
                        if ((this.state.USER.orders_count < 11) || (this.state.USER.orders_count > 15)) { return false; }
                        break;
                    case 4:
                        if ((this.state.USER.orders_count < 16) || (this.state.USER.orders_count > 20)) { return false; }
                        break;
                }
            }
        } else if (SLIDE.auth == 2) {
            if (this.state.USER) {
                return false;
            }
        }

        return true;
    }

    reachGoal = () => {
        if (typeof window.yaCounter49140634 !== "undefined") {
            window.yaCounter49140634.reachGoal('BANNER_CLICK');
        }
    }

    renderSlides() {
        let SLIDES = [];
        for (let i = 0; i < this.props.SLIDES.length; i++) {
            if (this.doShowSlide(this.props.SLIDES[i])) {
                /// if (WIDTH > 760) {
                if ((typeof this.props.SLIDES[i].link !== "undefined") && (this.props.SLIDES[i].link.length)) {
                    if (this.props.SLIDES[i].link.substring(0, 3) == 'htt') {
                        SLIDES.push(
                            <a onClick={ () => this.reachGoal() } target="_blank" href={ this.props.SLIDES[i].link } key={ i }><img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].image } className="main_banner" /></a>
                        );
                    } else {
                        SLIDES.push(
                            <Link onClick={ () => this.reachGoal() } to={ this.props.SLIDES[i].link } key={ i }><img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].image } className="main_banner" /></Link>
                        );
                    }
                } else {
                    SLIDES.push(
                        <div key={ i }><img src={ CONFIG.API_C_DOMAIN + this.props.SLIDES[i].image } className="main_banner" /></div>
                    );
                }
                // } else {
                //     if ((typeof this.props.SLIDES[i].link !== "undefined") && (this.props.SLIDES[i].link.length)) {
                //         if (this.props.SLIDES[i].link.substring(0,3) == 'htt') {
                //             SLIDES.push(
                //                 <a onClick={() => this.reachGoal()} target="_blank" href={this.props.SLIDES[i].link} key={i}><img src={CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image} className="main_banner" /></a>
                //             );
                //         } else {
                //             SLIDES.push(
                //                 <Link onClick={() => this.reachGoal()} to={this.props.SLIDES[i].link} key={i}><img src={CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image} className="main_banner" /></Link>
                //             );
                //         }
                //     } else {
                //         SLIDES.push(
                //             <div key={i}><img src={CONFIG.API_C_DOMAIN + this.props.SLIDES[i].m_image} className="main_banner" /></div>
                //         );
                //     }                
                // }
            }
        }

        return SLIDES;
    }

    render() {
        if (!this.props.SLIDES) {
            return false;
        }

        if (!this.props.SLIDES.length) {
            return false;
        }

        if (this.props.SLIDES.length > 1) {
            let SETTINGS = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                className: '',
                arrows: true,
                swipeToSlide: true,
                autoplay: true,
                autoplaySpeed: 4000
            };

            return (
                <div className="container mainCarousel">
                    <div className="row">
                        <div className="col-12">
                            <Slider ref={ c => (this.slider = c) } { ...SETTINGS }>
                                { this.renderSlides() }
                            </Slider>
                        </div>
                    </div>
                </div>
            );
        } else {
            let IMAGE_SOURCE = CONFIG.API_C_DOMAIN + this.props.SLIDES[0].m_image;
            ///if (WIDTH > 760) {
            IMAGE_SOURCE = CONFIG.API_C_DOMAIN + this.props.SLIDES[0].image;
            //}
            return (
                <div className="container mainCarousel">
                    <div className="row">
                        <div className="col-12">
                            <img src={ IMAGE_SOURCE } className="main_banner" />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Adverts);