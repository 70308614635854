import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import OrderCard from './../../../APP/COMPONENTS/ContentParts/OrderCard';

function mapStateToProps(state) {
    return {
        USER: state.USER
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class MobileLatestOrders extends Component {

    /// CONSTRUCTOR ///
    constructor(props)
    {
        super(props);
        this.state = {
            LATEST: false
        };
    }

    componentDidMount()
    {
        this.getLatestOrder();
    }

    getLatestOrder = async () =>
    {
        try {
            let SEND_DATA = {
                action:    'getLatestOrder',
                apikey:    this.props.USER.TOKEN
            };
            let data = await sendPostRequest(SEND_DATA,CONFIG.API_GATE);
            if (data !== false) {
            	this.renderLatest(data.orders)
            }
        } catch (error) {
            console.log('BAD PRODUCTS TIMER');
        }
    }

    renderLatest(ORDERS)
    {
    	let LATEST_ORDERS = [];

    	for (let i in ORDERS) {
            let ORDER = ORDERS[i];
            LATEST_ORDERS.push(<OrderCard key={i} order={ORDER} />);
    	}
		
		if (LATEST_ORDERS.length) {
			this.setState({LATEST: LATEST_ORDERS});
		}
    }

    render()
    {
        if (!this.state.LATEST) {
            return false;
        }

        return (
            <div>
                {this.state.LATEST}
            </div>
        );
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(MobileLatestOrders);