import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'
import { sendPostRequest } from "./../../../APP/HELPERS/requests"

import InputMask from "react-input-mask";

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ProductRowRec from "./../../../APP/COMPONENTS/ContentParts/ProductRowRec"

function mapStateToProps(state) {
    return {
        USER: state.USER,
        CART: state.CART,
        PRODUCTS: state.PRODUCTS,
        REC: true
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(ReduxActions, dispatch)
}

class CartButton extends Component {

    /// CONSTRUCTOR ///
    constructor(props) {
        super(props);
        this.state = {
            SUM: 0,
            CART: props.CART,
            PRODUCTS: props.PRODUCTS
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.CART !== prevProps.CART) {
            this.setState({ SUM: 0, CART: this.props.CART, PRODUCTS: this.props.PRODUCTS });
        }
    }

    countCart() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let KEYS = Object.keys(CART);
        let SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID];
                        SUM += ROW_SUM;
                    }
                }
            }
        }

        return SUM;
    }

    goToOrder() {
        if (typeof window.yaCounter49140634 !== "undefined") {
            window.yaCounter49140634.reachGoal('makeOrder');
        }
        this.props.openCart(false);
        this.props.openOrder(true);
        ///window.location.href = CONFIG.API_C_DOMAIN + '/cart?content=' + JSON.stringify(this.props.CART);
    }

    rmFixed(numb) {
        numb = numb.toFixed(3);
        numb = this.removeTrails(numb);
        return numb;
    }

    removeTrails(value) {
        value = value.toString()
        if (value.indexOf('.') === -1) {
            return value
        }
        var cutFrom = value.length - 1
        do {
            if (value[cutFrom] === '0') {
                cutFrom--;
            }
        } while (value[cutFrom] === '0')
        if (value[cutFrom] === '.') {
            cutFrom--;
        }
        return value.substr(0, cutFrom + 1)
    }

    checkMaximum() {
        let PRODUCT = this.state.PRODUCT;
        if (this.props.AMOUNT + 1 <= PRODUCT.availableones) {
            return true;
        }
        return false;
    }

    checkCartContents() {
        let PRODUCTS = this.props.PRODUCTS;
        let CART = this.props.CART;
        let RESULT = 0;
        let KEYS = Object.keys(CART);
        let SUM = 0;

        if (KEYS.length) {
            for (let ID of KEYS) {
                if (typeof PRODUCTS[ID] !== "undefined") {
                    if (PRODUCTS[ID].availableones >= CART[ID]) {
                        let ROW_SUM = PRODUCTS[ID].price * CART[ID];
                        SUM += ROW_SUM;
                        RESULT = 1;
                    }
                }
            }
        }

        this.SUM = SUM;

        return RESULT;
    }

    renderCart() {
        let CART_OK = 0;

        let count = 0;
        for (let k in this.props.CART) {
            ++count;
        }

        if (count > 0) {
            CART_OK = this.checkCartContents();
        }

        if (CART_OK == 1) {
            return this.renderCartContent();
        } else {
            return this.renderCartEmpty();
        }
    }

    renderCartEmpty() {

    }

    renderCartContent() {
        return (
            <div>
                { this.renderMessage() }
                { this.renderProducts() }

            </div>
        );
    }

    render() {
        let SUM = this.countCart();

        if (SUM >= CONFIG.MINIMAL) {
            return (
                <div className="modal_bottom" id="cart-submit" data-id="cart-submit">
                    <a className="orange-button hoverable" onClick={ () => this.goToOrder() }>
                        ÐžÑ„Ð¾Ñ€Ð¼Ð¸Ñ‚ÑŒ Ð·Ð°ÐºÐ°Ð·
                    </a>
                </div>
            );
        } else {
            if (SUM > 0) {
                let LEFT = CONFIG.MINIMAL - SUM;

                return (
                    <div className="modal_bottom" id="cart-submit" data-id="cart-submit">
                        <a className="close-modal-clean orange-button no-working" onClick={ () => this.props.openCart(false) }>
                            ÐÑƒÐ¶Ð½Ð¾ ÐµÑ‰Ñ‘ Ð´Ð¾Ð±Ð°Ð²Ð¸Ñ‚ÑŒ Ð½Ð°
                            <span className="addmore">{ LEFT } Ñ€</span>
                        </a>
                    </div>
                );
            } else {
                return false;
            }
        }
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(CartButton);